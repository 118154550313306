import { ReactElement, FC } from "react";
import { LooseObject } from "../../interfaces/LooseObject";
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { IRole } from "../../services/role.service";

const AddRole: FC<LooseObject> = (props: any): ReactElement => {

  const getRoleList = () => {
    return props.roleList.map((role: IRole) => (
      <MenuItem key={role.id} value={role.id}>
        {role.name}
      </MenuItem>
    ));
  };

  return (
    <Box
      component="main"
      sx={{
        paddingLeft: 0
       
      }}
    >
      <Box
        sx={{
          padding: "0px 25px 0px 25px",
          textAlign: "left",
          display: "inline-block",
        }}
      >
        <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
          <TextField
            sx={{ marginBlock: "15px 10px" }}
            required
            id="outlined-user-name"
            label="Name"
            placeholder="Name"
            size="small"
            fullWidth
            value={props.name}
            onChange={props.handleUserNameChange}
          />
        </FormControl>
        <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
          <TextField
            sx={{ marginBlock: "15px 10px" }}
            required
            id="outlined-email"
            label="Email"
            placeholder="Email"
            size="small"
            fullWidth
            value={props.email}
            onChange={props.handleEmailChange}
          />
        </FormControl>

        <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
          <FormLabel htmlFor="add-role-list-select">Role</FormLabel>
          <Select
            defaultValue=""
            id="role-list-select"
            label="Role"
            value={String(props.role?.id || "")}
            onChange={props.handleRoleChange}
            fullWidth
            displayEmpty={true}
          >
            <MenuItem value="">Select Role</MenuItem>
            {getRoleList()}
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
          <TextField
            sx={{ marginBlock: "15px 10px" }}
            type="password"
            required
            id="outlined-password"
            label="Password"
            placeholder=""
            size="small"
            fullWidth
            
            value={props.password}
            onChange={props.handlePasswordChange}
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default AddRole;
