import { ApiService } from "./api.service";

export const LocationColumnSetting: any = {
  locationName: { label: "Location", visibility: true, order: 1 },
  latitude: { label: "Latitude", visibility: true, order: 2 },
  longitude: { label: "Longitude", visibility:true, order:3},
  name: { label: "Job Safety", visibility: true, order: 4 },   
  criticalActivities:{ label: "Critical Activities", visibility: true, order: 5 },
  lifeSavingRules:{ label: "Life Saving Rules", visibility: true, order: 5 },
  status: { label: "Status", visibility: true, order: 6 } ,
  startDate: { label: "Start Date", visibility: true, order: 7 },  
};

export class JobSafetyAnalysisWorksheetService {
    get() {
        return ApiService.get("ElectronicalPermittingPortal/GetStatusByLocations");
    }

    getDetailslist(longitude: string | number,latitude: string | number,asset:string,locationName: string) {
        return ApiService.get(`ElectronicalPermittingPortal/GetFormDetailsByLocation?longitude=${longitude}&latitude=${latitude}&asset=${asset}&location=${locationName}`);
    }
    GetAllAssetsList()
    {
        return ApiService.get(`ElectronicalPermittingPortal/GetAllAssetsList`);
    } 
    GetAllLocationList(asset:string)
    { console.log(asset);
        return ApiService.get(`ElectronicalPermittingPortal/GetAllLocations?asset=${asset}`);
    } 
}
