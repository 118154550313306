import { ApiService } from "./api.service";

export interface INotification {
  id: number | string;
}

export const NotificationColumnSetting: any = {
  id: { label: "Id", visibility: false, order: 0 },
  title: { label: "Title", visibility: true, order: 1, minWidth: 200 },
  message: { label: "Message ", visibility: true, order: 2, minWidth: 280 },
  created: { label: "Created Date", visibility: true, order: 3, maxWidth: 150 },
  createdBy: { label: "Created By", visibility: true, order: 4, maxWidth: 170 },
};

export class NotificationService {
  get() {
    return ApiService.get("Notification");
  }

  post(request: any) {
    return ApiService.post(
      "Notification/CreateNotification",
      JSON.stringify(request)
    );
  }

  delete(id: number | string) {
    return ApiService.delete(`Notification/${id}`);
  }
}
