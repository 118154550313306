import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { FC } from "react";
import { DRAWER_WIDTH } from "../constants/components";
import { AccountCircle } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth.service";

import {
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";

export const PrimaryAppBar: FC = () => {

  const authService = new AuthService();
  const navigate = useNavigate();

  const [auth, setAuth] = React.useState(authService.isAuthenticated());
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    handleClose();
    navigate("/");
    authService.logout();
    window.location.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={auth}
              onChange={handleChange}
              aria-label="login switch"
            />
          }
          label={auth ? "Logout" : "Login"}
        />
      </FormGroup>
      <AppBar position="fixed" sx={{ bgcolor: "background.default" }}>
        <Toolbar sx={{ mr: 2, marginLeft: DRAWER_WIDTH }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "primary.900" }}
          >
            <strong>Lower 48 HSE</strong>
          </Typography>
          {auth && (
            <div style={{color:"black"}}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem> <br />
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
