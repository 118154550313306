import React, { ReactElement, FC } from "react";
import { LooseObject } from "../../interfaces/LooseObject";
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  IconButton,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  SelectChangeEvent,
} from "@mui/material";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ICategory } from "../../services/category.service";

const AddCategory: FC<LooseObject> = ({
  onChange,
  rows,
}: any): ReactElement => {
  const [addCategoryConfig, setAddCategoryConfig] = React.useState({
    parentCategoryId: "",
    editCategoryId: "",
    categoryList: rows || ([] as any[]),
    category: {} as any,
    iconImage: "",
    previewIconImage: "",
    url: "",
    name: "",
    isLink: false,
  } as any);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    addCategoryConfig[event.target.name] = event.target.value;
    setAddCategoryConfig({ ...addCategoryConfig });
    onChange(addCategoryConfig);
  };

  const handleIconImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newIconImage: any = event?.target?.files;
    addCategoryConfig.iconImage = newIconImage[0];
    addCategoryConfig.previewIconImage = URL.createObjectURL(newIconImage[0]);
    setAddCategoryConfig({ ...addCategoryConfig });
    onChange(addCategoryConfig);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    const categoryId = parseInt(event.target.value as string);
    const parent = rows.filter(
      (category: ICategory) => category.id === categoryId
    );
    addCategoryConfig.category = parent[0] || {};
    addCategoryConfig.parentCategoryId = addCategoryConfig.category.id;
    setAddCategoryConfig({ ...addCategoryConfig });
    onChange(addCategoryConfig);
  };

  const getCategoryList = () => {
    return addCategoryConfig.categoryList.map((category: ICategory) => (
      <MenuItem key={category.id} value={category.id}>
        {category.name}
      </MenuItem>
    ));
  };

  return (
    <Box
      component="main"
      sx={{
        paddingLeft: 0,
        height: "100%",
      }}
    >
      <Box
        sx={{
          padding: "0px 25px 0px 25px",
          textAlign: "left",
          display: "inline-block",
          maxWidth: "500px",
          width: "500px",
          minHeight: "300px",
        }}
      >
        <FormControl size="small" fullWidth sx={{ marginBlock: "5px" }}>
          <TextField
            name="name"
            sx={{ marginBlock: "10px" }}
            required
            id="outlined-category-name"
            label="Category Name"
            placeholder="Category Name"
            size="small"
            fullWidth
            value={addCategoryConfig.name}
            onChange={handleOnChange}
          />
        </FormControl>

        <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
          <FormLabel htmlFor="add-parent-category-list-select">
            Parent Category
          </FormLabel>
          <Select
            name="category"
            defaultValue=""
            id="category-list-select"
            label="Category"
            value={String(addCategoryConfig.category?.id || "")}
            onChange={handleCategoryChange}
            fullWidth
            displayEmpty={true}
          >
            <MenuItem value="">Select Parent Category</MenuItem>
            {getCategoryList()}
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
          <FormControlLabel
            sx={{ marginBlock: "5px" }}
            control={
              <Checkbox
                name="isLink"
                checked={addCategoryConfig.isLink}
                onChange={(event) => {
                  addCategoryConfig.isLink = event.target.checked;
                  setAddCategoryConfig({ ...addCategoryConfig });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Link"
          />
        </FormControl>
        {addCategoryConfig.isLink && (
          <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
            <TextField
              sx={{ marginBlock: "15px 10px" }}
              required
              name="url"
              id="outlined-link-url"
              label="Url"
              placeholder="https://example.com"
              size="small"
              fullWidth
              value={addCategoryConfig.url}
              onChange={handleOnChange}
            />
          </FormControl>
        )}

        {!addCategoryConfig.parentCategoryId && (
          <div>
            <Stack direction="row" spacing={0}>
              <IconButton
                id="upload-iconImage"
                color="primary"
                aria-label="upload picture"
                component="label"
                size="small"
                title=" Upload Files "
                sx={{ margin: 0, padding: 0, marginBlock: "10px" }}
              >
                <input
                  hidden
                  name="iconImage"
                  type="file"
                  onChange={handleIconImageChange}
                  accept="image/*"
                />
                Upload Icon &nbsp; &nbsp; <CloudUploadIcon fontSize="large" />
              </IconButton>
            </Stack>
            <p>Recommended icon size is 60X60</p>
          </div>
        )}

        {!addCategoryConfig.parentCategoryId &&
          addCategoryConfig.previewIconImage && (
            <Box>
              <img
                src={addCategoryConfig.previewIconImage}
                alt={addCategoryConfig.title}
                height="100px"
                width="100px"
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default AddCategory;
