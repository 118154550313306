import React, { ReactElement, FC, useEffect, ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import {
  INotification,
  NotificationColumnSetting,
  NotificationService,
} from "../../services/notification.service";

import { LooseObject } from "../../interfaces/LooseObject";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, TextField } from "@mui/material";
import { DRAWER_WIDTH } from "../../constants/components";
import SnackBar from "../../components/SnackBar";
import {
  GetColumnVisibilityModel,
  GetSortedColumnByOrder,
} from "../../utils/grid.utils";
import Dialog from "../../components/Dialog";
import Editor from "../../components/Editor";
import LoadingOverlay from "react-loading-overlay-ts";
import { useConfirm } from "material-ui-confirm";

import { StringUtils } from "../../utils/string.utils";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: "#333",
    backgroundColor: "#efeeee",
  },
}));

const notificationService = new NotificationService();

const Notification: FC<LooseObject> = (): ReactElement => {
  const confirm = useConfirm();

  const [notificationConfig, setNotificationConfig] = React.useState({
    loading: false,
    grid: {
      rows: [] as any[],
      columns: [] as any[],
    } as any,
    dialogConfig: {
      showSendNotificationDialog: false,
      showViewNotificationDialog: false,
      notificationText: "",
      notificationTitle: "",
    } as any,
    snakeBarConfig: {
      isOpen: false,
      type: "",
      message: "",
    },
  });

  const resetConfig = () => {
    const { dialogConfig } = notificationConfig;
    dialogConfig.showSendNotificationDialog = false;
    dialogConfig.showViewNotificationDialog = false;
    dialogConfig.notificationText = "";
    dialogConfig.notificationTitle = "";
    setNotificationConfig({
      ...notificationConfig,
      loading: false,
      dialogConfig,
    });
  };

  const showAlert = (message: string, type: string = "success") => {
    const { snakeBarConfig } = notificationConfig;
    snakeBarConfig.isOpen = true;
    snakeBarConfig.message = message;
    snakeBarConfig.type = type;
    setNotificationConfig({
      ...notificationConfig,
      snakeBarConfig,
    });
  };

  const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { snakeBarConfig } = notificationConfig;
    snakeBarConfig.isOpen = false;
    snakeBarConfig.message = "";
    snakeBarConfig.type = "";
    setNotificationConfig({
      ...notificationConfig,
      snakeBarConfig,
    });
  };

  const handleSendNotification = () => {
    const { dialogConfig } = notificationConfig;
    dialogConfig.showSendNotificationDialog = true;
    setNotificationConfig({ ...notificationConfig, dialogConfig });
  };

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { dialogConfig } = notificationConfig;
    dialogConfig.notificationTitle = event.target.value;
    setNotificationConfig({ ...notificationConfig, dialogConfig });
  };

  const handleTextChange = (value: string) => {
    const { dialogConfig } = notificationConfig;
    dialogConfig.notificationText = value;
    setNotificationConfig({ ...notificationConfig, dialogConfig });
  };

  const editorProps = {
    placeHolder: "Write push notification message...",
    onChange: handleTextChange,
    defaultValue: notificationConfig.dialogConfig.notificationText,
    readOnly: notificationConfig.dialogConfig.showViewNotificationDialog,
  };

  const notificationDialogProps = {
    id: "push-notification-dialog",
    title: !!notificationConfig.dialogConfig.showSendNotificationDialog
      ? "Push Notification"
      : "View Notification",
    closeButtonText: "Cancel",
    contentText: "",
    visibleActionButton:
      notificationConfig.dialogConfig.showSendNotificationDialog,
    submitButtonText: "Send",
    customComponent: (
      <Box>
        <TextField
          sx={{ marginBlock: "15px 10px" }}
          required
          id="notification-title"
          label="Title"
          placeholder="Enter Summary"
          size="small"
          fullWidth
          value={notificationConfig.dialogConfig.notificationTitle}
          onChange={handleTitleChange}
        />
        <Editor {...editorProps} />
      </Box>
    ),
    closeAction: () => {
      resetConfig();
    },
    submitAction: () => {
      setNotificationConfig({ ...notificationConfig, loading: true });
      notificationService
        .post({
          title: notificationConfig.dialogConfig.notificationTitle,
          message: notificationConfig.dialogConfig.notificationText,
        })
        .then((result: any) => {
          showAlert("Notification Sent Successfully");
          loadNotificationData();
          resetConfig();
        })
        .catch((error) => {
          showAlert(StringUtils.toErrorMessage(error), "error");
          setNotificationConfig({ ...notificationConfig, loading: false });
        });
    },
  };

  const viewNotification = React.useCallback(
    (row: any) => () => {
      const { dialogConfig } = notificationConfig;
      dialogConfig.showViewNotificationDialog = true;
      dialogConfig.showSendNotificationDialog = false;
      dialogConfig.notificationText = row.message;
      dialogConfig.notificationTitle = row.title;
      setNotificationConfig({ ...notificationConfig, dialogConfig });
    },
    [notificationConfig]
  );

  const deleteNotification = React.useCallback(
    (row: any) => () => {
      confirm({ description: "Do you want to delete the notification?" })
        .then(() => {
          setNotificationConfig({ ...notificationConfig, loading: true });
          notificationService
            .delete(row.id)
            .then(() => {
              showAlert("Notification deleted successfully");
              resetConfig();
              loadNotificationData();
            })
            .catch((error) => {
              showAlert(StringUtils.toErrorMessage(error), "error");
              setNotificationConfig({ ...notificationConfig, loading: false });
            });
        })
        .catch(() => {});
    },
    []
  );

  const loadNotificationData = () => {
    setNotificationConfig({ ...notificationConfig, loading: true });
    const notificationResponse: Promise<INotification[]> =
      notificationService.get();
    notificationResponse
      .then((result: INotification[]) => {
        const docsRows = result.map((row: any, index: number) => {
          return {
            id: index,
            createdBy: row.createdBy || "Admin",
            ...row,
          };
        });

        const docsColumns: any[] = GetSortedColumnByOrder(
          NotificationColumnSetting
        ).map((key) => {
          const column: any = {
            field: key,
            headerName: NotificationColumnSetting[key]?.label,
            minWidth: NotificationColumnSetting[key]?.minWidth,
            width: NotificationColumnSetting[key]?.width,
            maxWidth: NotificationColumnSetting[key]?.maxWidth,
            flex: 1,
            renderCell: (params: LooseObject) => (
              <span title={params.row[key]} className="table-cell-trucate">
                {params.row[key]}
              </span>
            ),
          };
          switch (column.field) {
            case "created":
              column.renderCell = (params: LooseObject) => {
                return new Date(params.row[key]).toLocaleString();
              };
              break;
          }
          return column;
        });

        docsColumns.push({
          field: "actions",
          type: "actions",
          headerName: "Actions",
          minWidth: 60,
          width: 60,
          maxWidth: 70,
          flex: 1,
          getActions: (params: any) => [
            <GridActionsCellItem
              icon={<VisibilityOffIcon />}
              title="View Document"
              label="View Document"
              onClick={viewNotification(params.row)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              title="Delete Notification"
              label="Delete Notification"
              onClick={deleteNotification(params.row)}
            />,
          ],
        });
        const { grid } = notificationConfig;
        grid.rows = docsRows;
        grid.columns = docsColumns;
        setNotificationConfig({ ...notificationConfig, grid, loading: false });
      })
      .catch((error) => {
        showAlert(StringUtils.toErrorMessage(error), "error");
        setNotificationConfig({ ...notificationConfig, loading: false });
      });
  };

  useEffect(() => {
    loadNotificationData();
    return () => {};
  }, []);

  return (
    <LoadingOverlay
      active={notificationConfig.loading}
      spinner
      className="hsc-loader"
    >
      <Box
        component="main"
        sx={{
          paddingLeft: DRAWER_WIDTH,
          background: "#f8f6f6",
          height: "100%",
          paddingBottom: "120px",
        }}
      >
        <Box
          sx={{
            padding: "25px",
            width: "100%",
            background: "#f8f6f6",
            height: "100%",
          }}
        >
          <Box sx={{ textAlign: "right", marginBlock: "10px" }}>
            <Button
              variant="contained"
              onClick={handleSendNotification}
              color="primary"
              disableFocusRipple={true}
              disableRipple={true}
            >
              Send Notification
            </Button>
          </Box>
          {(notificationConfig.dialogConfig.showSendNotificationDialog ||
            notificationConfig.dialogConfig.showViewNotificationDialog) && (
            <Dialog {...notificationDialogProps} />
          )}
          <StripedDataGrid
            key="internal-document-grid"
            initialState={{
              columns: {
                columnVisibilityModel: GetColumnVisibilityModel(
                  NotificationColumnSetting
                ),
              },
            }}
            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
            headerHeight={40}
            rowHeight={35}
            disableSelectionOnClick
            rows={notificationConfig.grid.rows}
            columns={notificationConfig.grid.columns}
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
          <SnackBar
            id="snackbar-notification"
            message={notificationConfig.snakeBarConfig.message}
            type={notificationConfig.snakeBarConfig.type || "success"}
            open={notificationConfig.snakeBarConfig.isOpen}
            close={handleSnackBarClose}
          />
        </Box>
      </Box>
    </LoadingOverlay>
  );
};

export default Notification;
