import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LooseObject } from "../interfaces/LooseObject";

export default function Editor(props: LooseObject) {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <ReactQuill
      style={{ height: "200px" }}
      modules={props.readOnly ? {} : modules}
      theme="snow"
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      placeholder={props.placeHolder}
      readOnly={props.readOnly}
    />
  );
}
