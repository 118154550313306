import { ApiService } from "./api.service";

export class SignUpService {  
  
  
  async sendEmailOtp({ email, password, isRegister }: any) {
    return new Promise((resolve, reject) => {
      const request = {
       email,
       password,
       isRegister
      };
      ApiService.post("Account/send-email-otp", JSON.stringify(request))
        .then((result) => {
          if (result && result.otp) { 
            sessionStorage.setItem("otp", result.otp);
            resolve(result);
          } else {
            reject(result);
          }
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  
  register({name, email, password,otp }: any) {
    const request = {
      Name:name,
      Email: email,
      Password:password,
      OTP:otp
    };
    return ApiService.post("user/SignUpContractor", JSON.stringify(request));
  } 
}
