import React, { ReactElement, FC, useState, useEffect, useRef } from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { JobSafetyAnalysisWorksheetService, LocationColumnSetting } from "../../services/JobSafetyAnalysisWorksheet.service";
import { StringUtils } from "../../utils/string.utils";
import SnackBar from "../../components/SnackBar";
import { Box, Button, SelectChangeEvent, FormControl,FormLabel, Autocomplete, TextField , MenuItem,Select} from "@mui/material";
import { DRAWER_WIDTH } from "../../constants/components";
import LoadingOverlay from "react-loading-overlay-ts";
import ExportExcel from '../../components/ExportCSV';

import TabPane, { TabPaneProps } from "../../components/TabPane";
import { styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { LooseObject } from "../../interfaces/LooseObject";
import {
    GetColumnVisibilityModel,
    GetSortedColumnByOrder,
} from "../../utils/grid.utils";
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        color: "#333",
        backgroundColor: "#efeeee",
    },
}));

declare global {
    interface Window {
        initMap: () => void;
    }
}
const headers = Object.keys(LocationColumnSetting).map(key => ({
    label: LocationColumnSetting[key].label,
    value: key
  }));
const JobSafetyAnalysisWorksheet: FC = (): ReactElement => {
    const jobSafetyAnalysisWorksheetService = new JobSafetyAnalysisWorksheetService();


    const mapInitialized = useRef(false); // <-- Add this line
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("");
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [snackBarType, setSnackBarType] = useState<string>("success");

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    };

    const [dataConfig, setDataConfig] = React.useState({
        grid: {
            dataRows: [] as any[],
            columns: [] as any[],
        } as any,
    });
    const [dataList, setDataList] = React.useState([] as any[]);
    const [assetList, setAssetList] = React.useState([] as any[]); 
    const [suggestions, setSuggestions] = React.useState([] as any[]); 
    const [currentTab, setCurrentTab] = React.useState("map" as string);

    const [asset, setAsset] = React.useState("" as string);
    const [location, setLocation] = React.useState("" as string);

    const handleTabChange = (newTabValue: string) => {
        setCurrentTab(newTabValue);
        loadMap();
    };

    const handleAssetChange = (event: SelectChangeEvent) => {
        const value = event.target.value as string;
        setAsset(value);
        setLocation('');
        getLocationList(value);
    };
    const handleChange = (event: React.SyntheticEvent, value: any | null) => {
        setLocation(value); 
    };

    const handleSearch = () => { 
        loadData(0,0,asset,location??"");
    };
    const getAssetList = () => {
        setLoading(true);
        Promise.all([jobSafetyAnalysisWorksheetService.GetAllAssetsList()])
            .then(([assetList]) => {
                setAssetList(assetList);
                setLoading(false);
            }) 
            .catch((error) => {
                setSnackBarMessage(StringUtils.toErrorMessage(error));
                setSnackBarType("error");
                setSnackBarOpen(true);
                setLoading(false);
            });
      };


      
    const getLocationList = (asset:string) => {
        setLoading(true);
        Promise.all([jobSafetyAnalysisWorksheetService.GetAllLocationList(asset)])
            .then(([locations]) => {             
                setSuggestions([...new Set(locations.map((location: any) => location.locationName))].sort()); 
                setLoading(false);
            }) 
            .catch((error) => {
                setSnackBarMessage(StringUtils.toErrorMessage(error));
                setSnackBarType("error");
                setSnackBarOpen(true);
                setLoading(false);
            });
      };

     const getAssetListDrop=()=>{ 
        return assetList.map((asset: any) => (
            <MenuItem key={asset.name} value={asset.name}>
              {asset.name}
            </MenuItem>
    ))
     };

    const loadData = (longitude:any,latitude:any,asset:string,locationName: string) => {
        setLoading(true);
        Promise.all([jobSafetyAnalysisWorksheetService.getDetailslist(longitude,latitude,asset,locationName)])
            .then(([dataList]) => {
                setDataList(dataList);

                const dataColumns: any[] = GetSortedColumnByOrder(
                    LocationColumnSetting
                ).map((key) => {
                    const column: any = {
                        field: key,
                        headerName: LocationColumnSetting[key]?.label,
                        minWidth: 20,
                        flex: 1,
                        renderCell: (params: LooseObject) => (
                            <span title={params.row[key]} className="table-cell-trucate">
                                {params.row[key]}
                            </span>
                        ),
                    };
                    return column;
                });
                const { grid } = dataConfig;
                grid.columns = dataColumns;
                grid.dataRows = dataList;

                setDataConfig({ ...dataConfig, grid });

                setLoading(false);
            })
            .catch((error) => {
                setSnackBarMessage(StringUtils.toErrorMessage(error));
                setSnackBarType("error");
                setSnackBarOpen(true);
                setLoading(false);
            });
    };

    const loadMap = () => {
        if (!document.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBJcto8WViEf4Ka_wg64r5f9dab1nKpnBs&callback=initMap"]`)) {
            // Load Google Maps script dynamically   
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBJcto8WViEf4Ka_wg64r5f9dab1nKpnBs&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            const markerclustererscript = document.createElement("script");
            markerclustererscript.src = `https://cdnjs.cloudflare.com/ajax/libs/js-marker-clusterer/1.0.0/markerclusterer.js`;
            markerclustererscript.async = true;
            markerclustererscript.defer = true;
            document.body.appendChild(markerclustererscript);

            script.onload = () => {
                initMap();
                mapInitialized.current = true;
            };
        }
        else {
            initMap();
        }
    }

    useEffect(() => {
        if (!mapInitialized.current) { // <-- Add this line
            loadMap(); // <-- Add this line
        } // <-- Add this line
        getAssetList();
    }, []);


    const initMap = () => {
        // Create map 

        setLoading(true);
        const mapElement = document.getElementById('map');
        const map = new window.google.maps.Map(mapElement as HTMLElement, {
            //center: { lat: 37.7749, lng: -122.4194 }, 
            center: {
                lat: 31.392321, lng: -99.170307
            },
            zoom: 7
        });

        jobSafetyAnalysisWorksheetService.get()
            .then((locations) => {

                // Add markers dynamically based on fetched data
                var markers2: any[] = [];
                locations.map((markerInfo: any, index: number) => {
                    const marker = new window.google.maps.Marker({
                        position: { lat: markerInfo.latitude, lng: markerInfo.longitude },
                        map: map,
                        title: markerInfo.locationName,
                        label: {
                            text: (markerInfo.inProgressCount + markerInfo.completeCount).toString(), // Display the price on the marker
                            color: 'white', // Text color
                            fontWeight: 'bold' // Bold font weight
                        }
                    });

                    // Add info window
                    const infowindow = new window.google.maps.InfoWindow({
                        content: `<b>Location: ${markerInfo.locationName}</b><br/><b>Critical Activities</b>: ${(markerInfo.inProgressCount + markerInfo.completeCount)} <br/><b>In Progress</b>: ${markerInfo.inProgressCount}<br/><b>Complete</b>: ${markerInfo.completeCount}<br/><b>Life Saving Rules</b>:   ${(markerInfo.lifeSavingCount)}<br/><a  href="#" class="sm-button" id="details-link">View Details</a>`
                    });

                    // Show info window when marker is clicked
                    marker.addListener('click', function () {
                        infowindow.open(map, marker);
                        setTimeout(() => {
                            const detailsLink = document.getElementById('details-link');
                            if (detailsLink) {
                                detailsLink.addEventListener('click', (e) => {
                                    e.preventDefault(); // Prevent the default anchor behavior
                                    setAsset(markerInfo.asset); 
                                    setLocation('');
                                    getLocationList(markerInfo.asset);
                                    loadData(markerInfo.longitude,markerInfo.latitude,"","");
                                    handleTabChange("detail");
                                });
                            }
                        }, 0);
                    });

                    markers2.push(marker);
                });
                const markerClusterer = new MarkerClusterer(map, markers2, {
                    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                    maxZoom: 10, // Adjust as needed
                });
                setLoading(false);
            })
            .catch((error) => {
                setSnackBarMessage(StringUtils.toErrorMessage(error));
                setSnackBarType("error");
                setSnackBarOpen(true);
                setLoading(false);
            });

    }

    const tabsProps: TabPaneProps = {
        defaultValue: "map",
        externalValue: currentTab,  // Pass currentTab as externalValue
        tabs: [
            {
                label: "Map View",
                value: "map",
                content: (
                    <Box>
                        <div id="map" style={{ height: '600px', width: '100%' }}></div>
                    </Box>
                ),
            },
            {
                label: "Report By Location",
                value: "detail",
                content: (
                    <Box>
                      
                      <FormControl size="small" sx={{ marginBlock: "10px", width: '80%' }}>
  <FormLabel htmlFor="asset-list-select">Asset</FormLabel>
  <Select
    name="asset"
    defaultValue=""
    id="asset-list-select"
    label="Asset"
    value={String(asset || "")}
    onChange={handleAssetChange}
    displayEmpty={true}
    sx={{ height: "45px" }}
    MenuProps={{
      PaperProps: {
        sx: {
          maxHeight: 150,
        },
      },
    }}
  >
    <MenuItem value="">Select Asset</MenuItem>
    {getAssetListDrop()}
  </Select>
</FormControl>

<FormControl size="small" sx={{ marginBlock: "10px", width: '80%' }}>
  <FormLabel htmlFor="location-list-select">Location</FormLabel>
  <Autocomplete
    disablePortal
    id="location-list-select"
    value={location}
    options={suggestions}
    getOptionLabel={(option: any) => option}
    onChange={handleChange}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Search Location...."
        sx={{
          '& .MuiInputBase-root': {
            height: '45px',
          },
          '& .MuiOutlinedInput-input': {
            padding: '9px 14px',
          },
          '& .MuiInputLabel-root': {
           lineHeight:'1rem'
          },
        }}
      />
    )}
  />
</FormControl>

<Box
  sx={{
    padding: "22px 0px 0px 0px",
    display: "inline-block",
    width: "20%",
    textAlign: "right"
  }}
>
  
  <Button
    variant="contained"
    onClick={handleSearch}
    color="primary"
    sx={{ height: "45px", margin: "10px" }}
    disableFocusRipple={true}
    disableRipple={true}
  >
    Search
  </Button>
  <ExportExcel data={dataList} headers={headers} fileName="job-safety-analysis-report.csv" />
</Box>

                        <StripedDataGrid
                            key="data-grid"
                            initialState={{
                                columns: {
                                    columnVisibilityModel:
                                        GetColumnVisibilityModel(LocationColumnSetting),
                                },
                            }}
                            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
                            headerHeight={40}
                            rowHeight={35}
                            disableSelectionOnClick
                            rows={dataConfig.grid.dataRows}
                            columns={dataConfig.grid.columns}
                            hideFooter={true}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                            }
                        /></Box>
                ),
            },
        ],
        // onTabChange={handleTabChange}
    };
   
    return (

        <LoadingOverlay active={loading} spinner className="hsc-loader">
            <Box
                component="main"
                sx={{
                    paddingLeft: DRAWER_WIDTH,
                    background: "#f8f6f6",
                    height: "100%",
                    paddingBottom: "0px"
                }}
            >
                <Box
                    sx={{
                        padding: "0px 15px 25px 15px",
                        display: "inline-block",
                        width: "100%"
                    }}
                >
                    <h2><strong>Job Safety Analysis Report</strong></h2>
                    <TabPane  {...tabsProps}></TabPane>
                    <SnackBar
                        id="snackbar-roleId"
                        message={snackBarMessage}
                        type={snackBarType || "success"}
                        open={snackBarOpen}
                        close={handleSnackBarClose}
                    />
                </Box>
            </Box>
        </LoadingOverlay>
    );
};

export default JobSafetyAnalysisWorksheet;
