import { Method, _Headers, Body } from "./types";
import { API_BASE_URL } from "../configs/AppConfig";

//const baseUrl = "https://cop.agisinfotech.com/api/";
export default class BaseRequestModel {
  constructor(
    private url: string,
    private method: Method,
    private headers: _Headers,
    private body?: Body
  ) {
    this.url = url;
    this.method = method || "GET";
    this.headers = headers || {};
    this.body = body;
  }

  hasJSONContentType(type: any) {
    return (
      type?.includes("application/octet-stream") ||
      type?.includes("application/json") ||
      type?.includes("application/problem+json")
    );
  }

  hasBlobContentType(type: any) {
    return type?.includes("application/octet-stream");
  }

  async request(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response: any = await fetch(`${API_BASE_URL}${this.url}`, {
          method: this.method,
          headers: this.headers,
          body: this.body,
        });

        let result = {};
        const contentType = response.headers.get("content-type");
        if (this.hasJSONContentType(contentType)) {
          if (this.hasBlobContentType(contentType)) {
            const contentDisposition: {} | null = response.headers.get(
              "Content-Disposition"
            );
            result = {
              blob: await response.blob(),
              contentDisposition,
            };
          } else {
            result = await response.json();
          }
        } else {
          result = response;
        }

        if (response.ok || response.status.toString().startsWith("2")) {
          resolve(result);
        } else {
          reject({ ...result, ...{ status: response.status } });
        }
      } catch (error) {
        reject({ error, status: 500 });
      }
    });
  }
}
