import React from 'react';
import Papa  from 'papaparse';
import { Box, Button } from "@mui/material";

type Header = {
  label: string;
  value: string;
};

type ExportCSVProps = {
  data: any[];
  headers: Header[];
  fileName: string;
};

const ExportCSV : React.FC<ExportCSVProps> = ({ data, headers, fileName }) => {
  const exportToCSV = () => {
    const csv = Papa.unparse({
      fields: headers.map(header => header.label),
      data: data.map(row => headers.map(header => row[header.value]))
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
      <Button
    variant="contained"
    onClick={exportToCSV}
    color="primary"
    sx={{ height: "45px", margin: "10px" }}
    disableFocusRipple={true}
    disableRipple={true} >
     Export CSV
  </Button> 
  
  );
};

export default ExportCSV;
