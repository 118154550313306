import { LooseObject } from "../interfaces/LooseObject";

import { API_BASE_URL } from "../configs/AppConfig";

export const GetColumnVisibilityModel = (model: any = {}) => {
  let columnModel: any = {};
  for (let key in model) {
    columnModel[key] = model[key].visibility;
  }
  return columnModel;
};

export const GetSortedColumnByOrder = (model: any) => {
  return Object.keys(model).sort((a, b) => {
    return model[a].order - model[b].order;
  });
};

export const FilterRowsByAccessLevel = (rows: any[], type: string) => {
  return rows.filter((row: any) => {
    const accessLevel = type === "public" ? 0 : 1;
    return row.documentAccessLevel === accessLevel;
  });
};

export const GetRowsMapByKey = (rows: any[], key: string) => {
  const map: LooseObject = {};
  rows.forEach((row: any) => {
    map[row[key]] = row;
  });
  return map;
};

export const GetDocumentType = (documentType: string) =>
  documentType.toLowerCase() === "file" ? "1" : "2";

export const isFileType = (row: any) => row.documentType === "1";
export const isLinkType = (row: any) => row.documentType === "2";

export async function dataUrlToFile(
  dataUrl: any,
  fileName: string
): Promise<File> {
  if (!(dataUrl instanceof File)) {
    try {
      const res: Response = await fetch(`data:image/png;base64,${dataUrl}`);
      const blob: Blob = await res.blob();
      return new File([blob], fileName, { type: "image/png" });
    } catch (err) {
    }
  }
  return dataUrl;
}

export const GetFileUrl = (id: string) => {
  return `${API_BASE_URL}Document/GetFile/${id}`;
};
