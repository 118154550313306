import BaseRequestModel from "../utils/base.request.model";
import { Body } from "../utils/types";

const withAuthHeader = (headers: any) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
};

export const ApiService = {
  get: (route: string): Promise<any> => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const newBase = new BaseRequestModel(route, "GET", withAuthHeader(headers));
    const result = newBase.request();

    return result;
  },
  post: (route: string, form: Body): Promise<any> => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const newBase = new BaseRequestModel(
      route,
      "POST",
      withAuthHeader(headers),
      form
    );
    const result = newBase.request();

    return result;
  },

  getDocument(route: string) {
    const newBase = new BaseRequestModel(route, "GET", withAuthHeader({}));
    const result = newBase.request();

    return result;
  },

  postDocument: (route: string, form: FormData): Promise<any> => {
    const newBase = new BaseRequestModel(
      route,
      "POST",
      withAuthHeader({}),
      form
    );
    const result = newBase.request();

    return result;
  },

  putDocument: (route: string, form: FormData): Promise<any> => {
    const newBase = new BaseRequestModel(
      route,
      "PUT",
      withAuthHeader({}),
      form
    );
    const result = newBase.request();

    return result;
  },

  put: (route: string, form: Body): Promise<any> => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const newBase = new BaseRequestModel(
      route,
      "PUT",
      withAuthHeader(headers),
      form
    );
    const result = newBase.request();

    return result;
  },

  patch: (route: string, form: Body): Promise<any> => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const newBase = new BaseRequestModel(
      route,
      "PATCH",
      withAuthHeader(headers),
      form
    );
    const result = newBase.request();

    return result;
  },

  delete: (route: string, form?: Body): Promise<any> => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
    };
    const newBase = new BaseRequestModel(
      route,
      "DELETE",
      withAuthHeader(headers),
      form
    );
    const result = newBase.request();

    return result;
  },
};
