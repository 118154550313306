import { ApiService } from "./api.service";

export interface IDocument {
  id: number | string;
}

export enum ViewType {
  MY_UPLOAD,
  VIEW_FILE,
}

export class DocumentService {
  get(view: ViewType) {
    let url = "Document" + (view === ViewType.MY_UPLOAD ? "/GetByUserId" : "");
    return ApiService.get(url);
  }

  getById(id: number | string) {
    return ApiService.getDocument(`Document/${id}`);
  }

  getDocumentById(id: string | number) {
    return ApiService.getDocument(`Document/GetFile/${id}`);
  }

  post(request: any) {
    const {
      type,
      files,
      categoryId,
      subCategoryId,
      title,
      url,
      documentAccessLevel,
      isOfflineAvailable,
      isDownloadRestricted,
    } = request;

    let formData = new FormData(); 
    let apiUrl = "Document/UploadMultipleDocument";

    if (type === "1") {
      files.forEach((file: Blob) => {
        formData.append("files", file);
      });
    } else {
      apiUrl = "Document/CreateDocumentSecure";
    }

    formData.append("title", title);
    formData.append("url", url);
    formData.append("IsOfflineAvailable", isOfflineAvailable || "false");
    formData.append("IsDownloadRestricted", isDownloadRestricted || "false");
    formData.append("DocumentAccessLevel", documentAccessLevel);
    formData.append("DocumentType", type);
    formData.append("isDocumentVisible", "true");

    formData.append(
      "CategoryId",
      subCategoryId?.toString() || categoryId?.toString()
    );

    return ApiService.postDocument(apiUrl, formData);
  }

  update(request: any) {
    const {
      id,
      type,
      files,
      categoryId,
      subCategoryId,
      title,
      url,
      documentAccessLevel,
      isOfflineAvailable,
      isDownloadRestricted,
    } = request;

    let formData = new FormData(); 
    if (type === "1") {
      files.forEach((file: Blob) => {
        formData.append("files", file);
      });

      if (files.length) {
        formData.append("isDocumentUpdated", "true");
      } else {
        formData.append("isDocumentUpdated", "false");
      }
    } else if (url) {
      formData.append("url", url);
      formData.append("isDocumentUpdated", "true");
    }

    formData.append("IsOfflineAvailable", isOfflineAvailable || "false");
    formData.append("IsDownloadRestricted", isDownloadRestricted || "false");
    formData.append("DocumentAccessLevel", documentAccessLevel);
    formData.append("title", title);
    formData.append("DocumentType", type);
    formData.append("isDocumentVisible", "true");

    formData.append(
      "CategoryId",
      subCategoryId?.toString() || categoryId?.toString()
    );

    return ApiService.putDocument(
      `Document/UpdateDocumentSecure/${id}`,
      formData
    );
  }

  patch(request: any) {
    const { id, isDocumentVisible } = request;
    let url = `Document/PatchDocument/${id}`;
    return ApiService.patch(
      url,
      JSON.stringify({ isDocumentVisible: isDocumentVisible })
    );
  }

  delete(id: number | string) {
    return ApiService.delete(`Document/${id}`);
  }
}
