import React, { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import FileCopyIcon from "@mui/icons-material/FileCopy";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CategoryIcon from "@mui/icons-material/Category";
import MapIcon from "@mui/icons-material/Map";
import { useNavigate, useLocation } from "react-router-dom";

import { DRAWER_WIDTH } from "../constants/components";
import { PrimaryAppBar } from "./AppBar";
import { Logo } from "./Logo";

const menus = [
  { name: "View Files", iconName: "viewFiles", pathname: "/" },
  {
    name: "Upload Files",
    iconName: "uploadFiles",
    pathname: "/upload-document",
  },
  { name: "My Uploads", iconName: "myUploads", pathname: "/document" },
  { name: "Push Notification", iconName: "notification", pathname: "/notification" },
  {
    name: "Category",
    iconName: "category",
    pathname: "/category",
  },
  { name: "Manage User", iconName: "manage", pathname: "/manage-user" },

    { name: "Job Safety", iconName: "map", pathname: "/job-safety" },
];

const getIcon = (name: string) => {
  switch (name) {
    case "viewFiles":
      return <FileCopyIcon />;
    case "uploadFiles":
      return <CloudUploadIcon />;
    case "myUploads":
      return <UploadFileIcon />;
    case "notification":
      return <NotificationsActiveIcon />;
    case "category":
      return <CategoryIcon />;
    case "manage":
          return <ManageAccountsIcon />;
      case "map":
          return <MapIcon />;
  }
};

const PermanentDrawerLeft: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const paths = menus.map((menu) => menu.pathname);
  const initIndex = paths.indexOf(location.pathname);
  const [selectedIndex, setSelectedIndex] = React.useState(
    initIndex !== -1 ? initIndex : 1
  );

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    const menu = menus[index] || 1;
    setSelectedIndex(index);
    if (menu.pathname) {
      navigate(menu.pathname);
    }
  };


  useEffect(() => {
    const index = paths.indexOf(location.pathname);
    setSelectedIndex(index !== -1 ? index : 1);
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <PrimaryAppBar />

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Logo />
        <Box sx={{ width: "auto", overflow: "hidden" }} role="presentation">
          <List
            component="nav"
            id="nav-list"
            aria-label={menus.map((menu) => menu.name).join(" ")}
          >
            {menus.map((menu, index) => (
              <ListItemButton
                key={`nav-${menu.name}`}
                sx={{ width: DRAWER_WIDTH, height: "54px", paddingLeft: "8px" }}
                selected={selectedIndex === index}
                onClick={(
                  event: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => handleListItemClick(event, index)}
              >
                <ListItemIcon sx={{ marginLeft: "10px", color: "inherit" }}>
                  {getIcon(menu.iconName)}
                </ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItemButton>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          background: "#f8f6f6",
          width: "100%",
        }}
      >
        <Toolbar />
      </Box>
      
    </Box>
  );
};

export default PermanentDrawerLeft;
