import { ReactElement, FC, ChangeEvent } from "react";
import { LooseObject } from "../interfaces/LooseObject";
import {
    Box,
    Button,
    Container,
    FormControl,
    TextField,
} from "@mui/material";
import React from "react";
import { Logo } from "./Logo";
import SnackBar from "./SnackBar";
import LoadingOverlay from "react-loading-overlay-ts";
import { AuthService } from "../services/auth.service"; 
import { SignUpService } from "../services/signup.service";
import { StringUtils } from "../utils/string.utils";

const Login: FC<LooseObject> = (props: any): ReactElement => {
    const [loading, setLoading] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarType, setSnackBarType] = React.useState("success");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [otp, setOTP] = React.useState("");
    const [isSent, setIsSent] = React.useState(false);
    const authService = new AuthService(); 
    const SignupService = new SignUpService();

    const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSnackBarOpen(false);
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value as string);
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value as string);
    };

    const handleOtpChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOTP(event.target.value as string);
    }; 
    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    };

    const handleLogin = () => {
        setLoading(true);
        if (!isSent) {
            SignupService
                .sendEmailOtp({ email: email, password: password, isRegister:false })
                .then((result: any) => {
                    if (result) {                       
                        setIsSent(true);
                        setSnackBarMessage("Please enter the OTP sent to your email.");
                        setSnackBarType("success");
                        setSnackBarOpen(true);
                    } else {
                        setSnackBarMessage("Failed to send OTP. Please try again.");
                        setSnackBarType("error");
                        setSnackBarOpen(true);
                    }
                    setLoading(false);
                })
                .catch((err: any) => {
                    setLoading(false);
                    setSnackBarMessage(StringUtils.toErrorMessage(err));
                    setSnackBarType("error");
                    setSnackBarOpen(true);
                });
        } else { 
        authService
            .login({ email, password ,otp})
            .then((result: any) => {
                if (result) {                   
                    setSnackBarMessage("Login Successfully");
                    setSnackBarType("success");
                    setSnackBarOpen(true);
                    props.action(true);                 
                } else {
                    setSnackBarMessage(result.message);
                    setSnackBarType("error");
                    setSnackBarOpen(true);
                    props.action(false);
                }
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false);
                setSnackBarMessage(StringUtils.toErrorMessage(err));
                setSnackBarType("error");
                setSnackBarOpen(true);
                setLoading(false);
                props.action(false);
            });
        }
    };

    const LogoProps: any = {
        height: "auto",
        width: "100%",
    };

    return (
        <React.Fragment>
            <Container
                sx={{
                    maxWidth: "100%",
                    width: "100%",
                    padding: "15px",
                    textAlign: "center",
                }}
            >
                <LoadingOverlay active={loading} spinner className="hsc-loader">
                    <Box
                        sx={{
                            padding: "25px 50px",
                            textAlign: "center",
                            background: "#f8f6f6",
                            width: "100%",
                            maxWidth: "500px",
                            margin: "0 auto",
                        }}
                        onKeyPress={handleKeyPress}
                    >
                        <SnackBar
                            id="snackbar-login"
                            message={snackBarMessage}
                            type={snackBarType}
                            open={snackBarOpen}
                            close={handleSnackBarClose}
                        />
                        <div>
                            <Logo {...LogoProps} />
                        </div>
                        <FormControl size="small" fullWidth sx={{ marginBlock: "5px" }}>
                            <TextField
                                sx={{ marginBlock: "10px" }}
                                required
                                id="outlined-email"
                                label="Email"
                                placeholder="Email"
                                size="small"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </FormControl>
                        <FormControl size="small" fullWidth sx={{ marginBlock: "5px" }}>
                            <TextField
                                sx={{ marginBlock: "10px" }}
                                required
                                id="outlined-user-name"
                                label="Password"
                                placeholder="Password"
                                size="small"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </FormControl>

                        <FormControl size="small" fullWidth sx={{ marginBlock: "5px", display: isSent ? '' : 'none' }}>
                            <TextField
                                sx={{ marginBlock: "10px" }}
                                required
                                id="outlined-otp"
                                label="OTP"
                                placeholder="OTP"
                                size="small"
                                name="otp"
                                disabled={!isSent}
                                className={props.shouldHide ? 'hidden' : undefined}
                                value={otp}
                                onChange={handleOtpChange}
                                error={otp == ""}
                                helperText={"OTP is required."}
                            />
                        </FormControl>
                        <FormControl
                            size="small"
                            sx={{ marginBlock: "15px", padding: "25px" }}
                        >
                            <Button
                                id="login-submit"
                                variant="contained"
                                onClick={handleLogin}
                                size="large"
                                color="primary"
                                disableRipple={false}
                                sx={{ padding: "5px 18px", bgcolor: "#c72825" }}
                            >
                                Login
                            </Button>
                        </FormControl>
                    </Box>
                </LoadingOverlay>
            </Container>
        </React.Fragment>
    );
};

export default Login;
