import { ApiService } from "./api.service";

export interface ICategory {
  id: number | string;
  name: string;
  parentCategoryId?: number;
  iconImage?: string;
  url?: string;
  isLink?: boolean;
}

export const CategoryColumnSetting: any = {
  id: { label: "Id", visibility: false, order: 0 },
  iconImage: {
    label: "Icon",
    visibility: true,
    order: 0,
    width: 60,
    minWidth: 30,
    maxWidth: 70,
  },
  name: { label: "Category Name", visibility: true, order: 1 },
  parentCategoryId: {
    label: "Parent Category Id ",
    visibility: false,
    order: 2,
  },
  parentCategoryName: {
    label: "Parent Category Name ",
    visibility: true,
    order: 3,
  },
  url: { label: "Url", visibility: true, order: 4 },
  createdBy: { label: "Created By", visibility: true, order: 5 },
};

export class CategoryService {
  get() {
    return ApiService.get("Category");
  }

  getTree() {
    return ApiService.get("Category/GetHierarchicalCategory");
  }

  getById(id: string | number) {
    return ApiService.get(`Category/${id}`);
  }

  async add(request: any) {
    const { iconImage, name, url, parentCategoryId, isLink } = request;
    let formData = new FormData();

    if (iconImage && iconImage instanceof File) {
      formData.append("iconImage", iconImage);
    }

    formData.append("name", name);
    formData.append("isLink", isLink);
    if (isLink && url) {
      formData.append("url", url);
    }
    parentCategoryId !== -1 &&
      formData.append("parentCategoryId", parentCategoryId);

    return ApiService.postDocument("Category/CreateCategory", formData);
  }

  async update(request: any) {
    const { iconImage, name, url, parentCategoryId, isLink } = request;
    let formData = new FormData();

    if (iconImage && iconImage instanceof File) {
      formData.append("iconImage", iconImage);
    }
    formData.append("isLink", isLink);
    formData.append("name", name);

    if (isLink && url) {
      formData.append("url", url);
    }

    if (parentCategoryId) {
      formData.append("parentCategoryId", parentCategoryId);
    }

    return ApiService.putDocument(
      `Category/UpdateCategory/${request.id}`,
      formData
    );
  }

  delete(id: number | string) {
    return ApiService.delete(`Category/${id}`);
  }
}
