import { ERROR_MESSAGE } from "../constants/components";

export const StringUtils = {
  toPascalCase: (value: string) => {
    return `${value}`
      .replace(new RegExp(/[-_]+/, "g"), " ")
      .replace(new RegExp(/[^\w\s]/, "g"), "")
      .replace(
        new RegExp(/\s+(.)(\w*)/, "g"),
        ($1, $2, $3) => `${$2.toUpperCase() + $3}`
      )
      .replace(new RegExp(/\w/), (s) => s.toUpperCase());
  },

  getFileNameFromDisposition: (disposition: any) => {
    if (disposition && disposition.toString().indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) return matches[1].replace(/['"]/g, "");
    }
    return "";
  },

  toErrorMessage: (error: any = {}) => {
    if (error.status === 401) {
      sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.reload();
      }, 300);
      return "Session Expired";
    } else if (error.status === 403) {
      return "You are not authorized to perform this operation";
    }

    const err = error.errors || error;
    if (err) {
      let firstKey = Object.keys(err)[0];
      if (firstKey) {
        return (
          err["Title"] || err["CategoryId"] || err[firstKey] || ERROR_MESSAGE
        );
      }
      return ERROR_MESSAGE;
    }
    return ERROR_MESSAGE;
  },
};
