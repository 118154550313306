import { Alert, AlertTitle } from "@mui/material";
import { DRAWER_WIDTH } from "../constants/components";
import { LooseObject } from "../interfaces/LooseObject";

export function ErrorHandler({ error, resetErrorBoundary }: LooseObject) {
  return (
    <Alert severity="error" sx={{ textAlign: "justify", paddingLeft: DRAWER_WIDTH }}>
      <AlertTitle>Error</AlertTitle>
      Something went wrong: <strong>{error.message}</strong>
    </Alert>
  );
}
