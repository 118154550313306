import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ReactNode,useEffect } from "react"; 

interface TabProp {
  label: string;
  value: string;
  content: ReactNode;
}

export interface TabPaneProps {
  defaultValue: string;
  externalValue?: string;
  tabs: Array<TabProp>; 
}

export default function TabPane(props: TabPaneProps) {
  const [value, setValue] = React.useState(props.defaultValue);

  useEffect(() => {
    if (props.externalValue && props.externalValue !== value) {
      setValue(props.externalValue);
    }
  }, [props.externalValue]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue); 
  };

  return (
    <Box sx={{ width: "100%", background: "#f8f6f6", height: "100%", paddingBottom: "100px" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {props.tabs.map((tab: TabProp, index: number) => (
              <Tab
                key={`${tab.label}-tab-${index}`}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </TabList>
        </Box>

        {props.tabs.map((tab: TabProp, index: number) => (
          <TabPanel
            key={`${tab.label}-tab-panel-${index}`}
            sx={{ height: "100%" }}
            value={tab.value}
          >
            {tab.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
