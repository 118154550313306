import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import { ConfirmProvider } from "material-ui-confirm";

import React from "react";
import { AuthService } from "./services/auth.service"; 

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#f8f6f6",
        main: "#C72825",
        dark: "#5C6C72",
        contrastText: "#fff",
        900: "#C72825",
      },
      secondary: {
        main: "#5C6C72",
        light: "#82e9de",
        dark: "#5C6C72",
        contrastText: "#333",
        900: "#5C6C72",
      },
      success: {
        main: "#2e7d32",
        light: "#4caf50",
        dark: "#1b5e20",
        contrastText: "#333",
        900: "#5C6C72",
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        "myraid",
        "sans-serif",
        '"Apple Color Emoji"',
      ].join(","),
    },
  });

  const authService = new AuthService();

  const [isAuthenticated, setIsAuthenticated] = React.useState(
    !!authService.isAuthenticated()
  );

  if (!isAuthenticated) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline /> 
        <Router> 
          <Routes>
          <Route
                  key="user-login"
                  path="/*"
                  element={<Login action={setIsAuthenticated}></Login>}
           />
          <Route
                  key="register-user"
                  path="/register"
                  element={<SignUp></SignUp>}
           /> 
           <Route
                  key="register-user"
                  path="/reset-password"
                  element={<ForgotPassword></ForgotPassword>}
           /> 
            </Routes>
            </Router>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box height="100vh" display="flex" flexDirection="column">
        <ConfirmProvider>
          <Router>
            <Navbar />
            <Routes>
              {appRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Routes>
            <Footer />
          </Router>
        </ConfirmProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
