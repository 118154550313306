import React, { ReactElement, FC, useEffect } from "react";
import { LooseObject } from "../../interfaces/LooseObject";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Button,
  IconButton,
  Stack,
  TextField,
  Chip,
  ListItem,
  Paper,
  Checkbox,
  FormGroup,
} from "@mui/material";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CategoryService, ICategory } from "../../services/category.service";
import { DocumentService } from "../../services/document.service";
import { ErrorHandler } from "../../components/Error";
import { DRAWER_WIDTH } from "../../constants/components";
import SnackBar from "../../components/SnackBar";
import {
  GetRowsMapByKey,
  isFileType,
  isLinkType,
} from "../../utils/grid.utils";
import LoadingOverlay from "react-loading-overlay-ts";
import { StringUtils } from "../../utils/string.utils";

const categoryService = new CategoryService();
const documentService = new DocumentService();

const UploadDocument: FC<LooseObject> = (): ReactElement => {
  const [documentConfig, setDocumentConfig] = React.useState({
    loading: false,
    formData: {
      allCategoryList: [] as any[],
      categoryList: [] as any[],
      subCategoryList: [] as any[],
      category: {} as any,
      subCategory: {} as any,
      title: "",
      url: "",
      type: "1",
      isOfflineAvailable: false,
      isDownloadRestricted: false,
      documentAccessLevel: "1",
      files: [] as any[],
    } as any,
    snakeBarConfig: {
      isOpen: false,
      type: "",
      message: "",
    },
  });
  const resetConfig = () => {
    setDocumentConfig({
      ...documentConfig,
      formData: {
        allCategoryList: documentConfig.formData.allCategoryList,
        categoryList: documentConfig.formData.categoryList,
        subCategoryList: [] as any,
        category: {} as any,
        subCategory: {} as any,
        title: "",
        url: "",
        type: "1",
        isOfflineAvailable: false,
        isDownloadRestricted: false,
        documentAccessLevel: "1",
        files: [] as any[],
      } as any,
      loading: false,
    });
  };

  const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { snakeBarConfig } = documentConfig;
    snakeBarConfig.isOpen = false;
    snakeBarConfig.message = "";
    snakeBarConfig.type = "";
    setDocumentConfig({
      ...documentConfig,
      snakeBarConfig,
    });
  };

  const showAlert = (message: string, type: string = "success") => {
    const { snakeBarConfig } = documentConfig;
    snakeBarConfig.isOpen = true;
    snakeBarConfig.message = message;
    snakeBarConfig.type = type;
    setDocumentConfig({
      ...documentConfig,
      snakeBarConfig,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { formData } = documentConfig;

    switch (event.target.name) {
      case "isOfflineAvailable":
      case "isDownloadRestricted":
        formData[event.target.name] = !!event.target.checked;
        break;
      default:
        formData[event.target.name] = event.target.value;
        break;
    }
    setDocumentConfig({ ...documentConfig, formData });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const id = event.target.value as string;
    const { formData } = documentConfig;
    switch (event.target.name) {
      case "category":
        const category = documentConfig.formData.allCategoryList.filter(
          (category: ICategory) => category.id === parseInt(id)
        );
        const subCategoryResponse =
          documentConfig.formData.allCategoryList.filter((cat: any) => {
            return cat.parentCategoryId === category[0]?.id;
          });

        formData.category = category[0];
        formData.subCategoryList = subCategoryResponse;
        formData.subCategory = {};
        break;
      case "subCategory":
        const subCategory = documentConfig.formData.subCategoryList.filter(
          (category: ICategory) => category.id === parseInt(id)
        );
        formData.subCategory = subCategory[0];
        break;

      case "documentAccessLevel":
        formData[event.target.name] = id;
        break;
    }
    setDocumentConfig({ ...documentConfig, formData });
  };

  const checkParentExists = (category: any, categoryMap: any) => {
    if (category.parentCategoryId === null) {
      return true;
    }
    return false;
  };

  const loadCategoryData = () => {
    setDocumentConfig({ ...documentConfig, loading: true });
    const categoryResponse: Promise<ICategory[]> = categoryService.get();
    categoryResponse
      .then((result: ICategory[]) => {
        const categoryMap = GetRowsMapByKey(result, "id");
        const parentCategoryList = result.filter((cate) => {
          return checkParentExists(cate, categoryMap);
        });

        const { formData } = documentConfig;
        formData.allCategoryList = result;
        formData.categoryList = parentCategoryList;
        formData.category = {};
        formData.subCategoryList = [];
        formData.subCategory = {};
        setDocumentConfig({ ...documentConfig, formData, loading: false });
      })
      .catch((error: any) => {
        showAlert(StringUtils.toErrorMessage(error), "error");
        setDocumentConfig({ ...documentConfig, loading: false });
      });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event?.target;
    const { formData } = documentConfig;
    if (input.files) {
      for (const file of input.files) {
        formData.files.push(file);
      }
    }
    setDocumentConfig({ ...documentConfig, formData });
  };

  const handleFileDelete = (index: number) => () => {
    const { formData } = documentConfig;

    const files = formData.files.filter(
      (_: any, fileIndex: number) => fileIndex !== index
    );

    if (!files.length) {
      formData.files = [];
      resetConfig();
    } else {
      formData.files = files;
      setDocumentConfig({ ...documentConfig, formData });
    }
  };

  useEffect(() => {
    loadCategoryData();
  }, []);

  const getCategoryList = () => {
    return documentConfig.formData.categoryList.map((category: ICategory) => (
      <MenuItem key={category.id} value={category.id}>
        {category.name}
      </MenuItem>
    ));
  };

  const getSubCategoryList = () => {
    return documentConfig.formData.subCategoryList.map(
      (category: ICategory) => (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      )
    );
  };

  try {
    const validateUploadRequest = (request: any) => {
      let errorMessage = null;
      if (request.type === "1" && !request.files?.length) {
        errorMessage = "Please upload a file";
      }
      return errorMessage;
    };

    const uploadDocument = () => {
      const { formData } = documentConfig;
      const {
        type,
        files,
        category,
        subCategory,
        title,
        url,
        documentAccessLevel,
        isOfflineAvailable,
        isDownloadRestricted,
      } = formData;
      const request = {
        type,
        files,
        categoryId: category?.id,
        subCategoryId: subCategory?.id,
        title,
        url,
        documentAccessLevel,
        isOfflineAvailable,
        isDownloadRestricted,
      };
      const validationMessage = validateUploadRequest(request);
      if (!validationMessage) {
        setDocumentConfig({ ...documentConfig, loading: true });
        documentService
          .post(request)
          .then((result) => {
            showAlert("Document(s) uploaded successfully");
            resetConfig();
          })
          .catch((error) => {
            showAlert(StringUtils.toErrorMessage(error), "error");
            setDocumentConfig({ ...documentConfig, loading: false });
          });
      } else {
        showAlert(validationMessage, "error");
        setDocumentConfig({ ...documentConfig, loading: false });
      }
    };

    return (
      <LoadingOverlay
        active={documentConfig.loading}
        spinner
        className="hsc-loader"
      >
        <Box
          component="main"
          sx={{
            paddingLeft: DRAWER_WIDTH,
            background: "#f8f6f6",
            height: "100%",
          }}
        >
          <Box
            sx={{
              padding: "0px 25px 75px 25px",
              textAlign: "left",
              display: "inline-block",
            }}
          >
            <h2>
              <strong> Upload File</strong>
            </h2>
            <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
              <FormLabel id="document-upload-type"></FormLabel>
              <RadioGroup
                row
                name="type"
                aria-labelledby="demo-row-radio-buttons-group-label"
                onChange={handleChange}
                value={documentConfig.formData.type}
              >
                <FormControlLabel value="1" control={<Radio />} label="File" />
                <FormControlLabel value="2" control={<Radio />} label="Link" />
              </RadioGroup>
            </FormControl>
            <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
              <FormLabel htmlFor="category-list-select">Category</FormLabel>
              <Select
                id="category-list-select"
                label="Category"
                name="category"
                value={String(documentConfig.formData.category?.id || "")}
                onChange={handleSelectChange}
                fullWidth
                displayEmpty={true}
                required
              >
                <MenuItem value="">Select Category</MenuItem>
                {getCategoryList()}
              </Select>
            </FormControl>
            {documentConfig.formData.subCategoryList.length > 0 && (
              <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
                <FormLabel htmlFor="sub-category-list-select">
                  Sub Category
                </FormLabel>
                <Select
                  id="sub-category-list-select"
                  label="Sub Category"
                  name="subCategory"
                  value={String(documentConfig.formData.subCategory?.id || "")}
                  onChange={handleSelectChange}
                  fullWidth
                  displayEmpty={true}
                  required
                >
                  <MenuItem value="">Select Sub Category</MenuItem>
                  {getSubCategoryList()}
                </Select>
              </FormControl>
            )}

            <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
              <FormLabel htmlFor="access-level-select">Access Level</FormLabel>
              <Select
                defaultValue="public"
                labelId="demo-select-small"
                id="access-level-select"
                value={documentConfig.formData.documentAccessLevel}
                label="Access Level"
                name="documentAccessLevel"
                onChange={handleSelectChange}
                fullWidth
                required
              >
                <MenuItem value="1">Public</MenuItem>
                <MenuItem value="0">Internal</MenuItem>
              </Select>
            </FormControl>
            {isFileType({ documentType: documentConfig.formData.type }) && (
              <Box>
                <FormGroup>
                  <FormControlLabel
                    sx={{ marginBlock: "5px" }}
                    control={
                      <Checkbox
                        disabled={
                          documentConfig.formData.documentAccessLevel === "0"
                        }
                        name="isOfflineAvailable"
                        checked={documentConfig.formData.isOfflineAvailable}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Available for Offline View"
                  />
                  <FormControlLabel
                    sx={{ marginBlock: "5px" }}
                    control={
                      <Checkbox
                        name="isDownloadRestricted"
                        disabled={documentConfig.formData.isOfflineAvailable}
                        checked={documentConfig.formData.isDownloadRestricted}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Restrict Download"
                  />
                </FormGroup>
                <Box
                  sx={{
                    marginBlock: "0px",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  
                  <Stack direction="row" spacing={0}>
                    <IconButton
                      id="upload-files"
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      size="small"
                      title=" Upload Files "
                      sx={{ margin: 0, padding: 0, marginBlock: "10px" }}
                    >
                      <input
                        hidden
                        type="file"
                        name="files"
                        multiple
                        onChange={handleFileChange}
                      />
                     
                      Upload File &nbsp; &nbsp;{" "}
                      <CloudUploadIcon fontSize="large" />
                    </IconButton>
                  </Stack>
                </Box>
                <Paper sx={{ display: "block", background: "transparent" }}>
                  {(documentConfig.formData.files || []).map(
                    (data: any, index: number) => (
                      <ListItem key={`file-${index}-${data.lastModified}`}>
                        <Chip
                          label={data.name}
                          onDelete={handleFileDelete(index)}
                        />
                      </ListItem>
                    )
                  )}
                </Paper>
              </Box>
            )}

            {isLinkType({ documentType: documentConfig.formData.type }) && (
              <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
                <TextField
                  sx={{ marginBlock: "15px 10px" }}
                  required
                  id="outlined-link-title"
                  name="title"
                  label="Title"
                  placeholder="Title"
                  size="small"
                  fullWidth
                  value={documentConfig.formData.title}
                  onChange={handleChange}
                />
                <TextField
                  sx={{ marginBlock: "15px 10px" }}
                  required
                  id="outlined-link-url"
                  name="url"
                  label="Link"
                  placeholder="https://example.com"
                  size="small"
                  fullWidth
                  value={documentConfig.formData.url}
                  onChange={handleChange}
                />
              </FormControl>
            )}
            <Box
              sx={{
                marginBlock: "0px",
                padding: "10px 0 0 0",
                margin: "0px",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={5}>
                <Button
                  variant="contained"
                  onClick={uploadDocument}
                  color="primary"
                >
                  Submit
                </Button>
                <Button
                  onClick={resetConfig}
                  color="secondary"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
          <SnackBar
            id="snackbar-update-document"
            message={documentConfig.snakeBarConfig.message}
            type={documentConfig.snakeBarConfig.type || "success"}
            open={documentConfig.snakeBarConfig.isOpen}
            close={handleSnackBarClose}
          />
        </Box>
      </LoadingOverlay>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default UploadDocument;
