import React, { ReactElement, FC } from "react";
import { LooseObject } from "../../interfaces/LooseObject";
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  IconButton,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  SelectChangeEvent,
} from "@mui/material";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ICategory } from "../../services/category.service";

const UpdateCategory: FC<LooseObject> = ({
  onChange,
  rows,
  state,
}: any): ReactElement => {
  const [updateCategoryConfig, setUpdateCategoryConfig] = React.useState({
    parentCategoryId: state.parentCategoryId || "",
    editCategoryId: state.editCategoryId || "",
    categoryList: rows || ([] as any[]),
    category: state.category || ({} as any),
    iconImage: state.iconImage || "",
    previewIconImage: state.previewIconImage || "",
    url: state.url || "",
    name: state.name || "",
    isLink: state.isLink || false,
  } as any);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCategoryConfig[event.target.name] = event.target.value;
    setUpdateCategoryConfig({ ...updateCategoryConfig });
    onChange(updateCategoryConfig);
  };

  const handleIconImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newIconImage: any = event?.target?.files;
    updateCategoryConfig.iconImage = newIconImage[0];
    updateCategoryConfig.previewIconImage = URL.createObjectURL(
      newIconImage[0]
    );
    setUpdateCategoryConfig({ ...updateCategoryConfig });
    onChange(updateCategoryConfig);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    const categoryId = parseInt(event.target.value as string);
    const parent = rows.filter(
      (category: ICategory) => category.id === categoryId
    );
    updateCategoryConfig.category = parent[0] || {};
    updateCategoryConfig.parentCategoryId = updateCategoryConfig.category.id;
    setUpdateCategoryConfig({ ...updateCategoryConfig });
    onChange(updateCategoryConfig);
  };

  const getCategoryList = () => {
    return updateCategoryConfig.categoryList.map((category: ICategory) => (
      <MenuItem key={category.id} value={category.id}>
        {category.name}
      </MenuItem>
    ));
  };

  return (
    <Box
      component="main"
      sx={{
        paddingLeft: 0,
        height: "100%",
      }}
    >
      <Box
        sx={{
          padding: "0px 25px 0px 25px",
          textAlign: "left",
          display: "inline-block",
        }}
      >
        <FormControl size="small" fullWidth sx={{ marginBlock: "5px" }}>
          <TextField
            name="name"
            sx={{ marginBlock: "10px" }}
            required
            id="outlined-category-name"
            label="Category Name"
            placeholder="Category Name"
            size="small"
            fullWidth
            value={updateCategoryConfig.name}
            onChange={handleOnChange}
          />
        </FormControl>

        <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
          <FormLabel htmlFor="add-parent-category-list-select">
            Parent Category
          </FormLabel>
          <Select
            name="category"
            defaultValue=""
            id="category-list-select"
            label="Category"
            value={String(updateCategoryConfig.category?.id || "")}
            onChange={handleCategoryChange}
            fullWidth
            displayEmpty={true}
          >
            <MenuItem value="">Select Parent Category</MenuItem>
            {getCategoryList()}
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
          <FormControlLabel
            sx={{ marginBlock: "5px" }}
            control={
              <Checkbox
                name="isLink"
                checked={updateCategoryConfig.isLink}
                onChange={(event) => {
                  updateCategoryConfig.isLink = event.target.checked;
                  setUpdateCategoryConfig({ ...updateCategoryConfig });
                  onChange(updateCategoryConfig);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Link"
          />
        </FormControl>
        {updateCategoryConfig.isLink && (
          <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
            <TextField
              sx={{ marginBlock: "15px 10px" }}
              required
              name="url"
              id="outlined-link-url"
              label="Url"
              placeholder="https://example.com"
              size="small"
              fullWidth
              value={updateCategoryConfig.url}
              onChange={handleOnChange}
            />
          </FormControl>
        )}

        {!updateCategoryConfig.parentCategoryId && (
          <div>
            <Stack direction="row" spacing={0}>
              <IconButton
                id="upload-iconImage"
                color="primary"
                aria-label="upload picture"
                component="label"
                size="small"
                title=" Upload Files "
                sx={{ margin: 0, padding: 0, marginBlock: "10px" }}
              >
                <input
                  hidden
                  name="iconImage"
                  type="file"
                  onChange={handleIconImageChange}
                  accept="image/*"
                />
                Upload Icon &nbsp; &nbsp; <CloudUploadIcon fontSize="large" />
              </IconButton>
            </Stack>
            <p>Recommended icon size is 60X60</p>
          </div>
        )}

        {!updateCategoryConfig.parentCategoryId &&
          updateCategoryConfig.previewIconImage && (
            <Box>
              <img
                src={updateCategoryConfig.previewIconImage}
                alt={updateCategoryConfig.title}
                height="100px"
                width="100px"
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default UpdateCategory;
