export const DashboardColumnSetting: any = {
  title: { label: "Document Title", visibility: true, order: 1, width: 125, minWidth: 200},
  activeDocumentVersion: { label: "Version", visibility: true, order: 2, width: 60, minWidth: 30, maxWidth: 70 },
  documentType: { label: "Type", visibility: true, order: 3, width: 50, minWidth: 30, maxWidth: 60 },
  categoryName: { label: "Category Name", visibility: true, order: 5 },
  createdBy: { label: "Created By", visibility: true, order: 6 },
  created: { label: "Created Date", visibility: true, order: 7, maxWidth:150 },
 
  documentAccessLevel: { label: "Access Level", visibility: false, order: 9 },
  lastModifiedBy: { label: "Last Modified Name", visibility: true, order: 10 },
  updated: { label: "Last Modified Date", visibility: true, order: 11},
  url: { label: "Link", visibility: false, order: 12 },
  isDownloadRestricted: {
    label: "Download Restriction",
    visibility: false,
    order: 11,
  },
  isOfflineAvailable: {
    label: "Offline Available",
    visibility: false,
    order: 12,
  },
  isDocumentVisible: { label: "Visible", visibility: true, order: 13, width: 50, minWidth: 30, maxWidth: 60 },
};
