import React, { ReactElement, FC, useEffect } from "react";
import { LooseObject } from "../../interfaces/LooseObject";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Button,
  IconButton,
  Stack,
  TextField,
  Chip,
  ListItem,
  Paper,
  Checkbox,
  FormGroup,
} from "@mui/material";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CategoryService, ICategory } from "../../services/category.service";
import { DocumentService } from "../../services/document.service";
import { DRAWER_WIDTH } from "../../constants/components";
import SnackBar from "../../components/SnackBar";
import {
  GetDocumentType,
  GetRowsMapByKey,
  isFileType,
  isLinkType,
} from "../../utils/grid.utils";
import { useParams } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay-ts";
import { StringUtils } from "../../utils/string.utils";

const categoryService = new CategoryService();
const documentService = new DocumentService();

const UpdateDocument: FC<LooseObject> = (): ReactElement => {
  const params = useParams();

  const [documentConfig, setDocumentConfig] = React.useState({
    loading: false,
    formData: {
      allCategoryList: [] as any[],
      categoryList: [] as any[],
      subCategoryList: [] as any[],
      category: {} as any,
      subCategory: {} as any,
      title: "",
      url: "",
      type: "1",
      isOfflineAvailable: false,
      isDownloadRestricted: false,
      documentAccessLevel: "1",
      files: [] as any[],
    } as any,
    snakeBarConfig: {
      isOpen: false,
      type: "",
      message: "",
    },
  });

  const resetConfig = () => {
    setDocumentConfig({
      ...documentConfig,
      formData: {
        allCategoryList: documentConfig.formData.allCategoryList,
        categoryList: documentConfig.formData.categoryList,
        subCategoryList: [] as any,
        category: {} as any,
        subCategory: {} as any,
        title: "",
        url: "",
        type: "1",
        isOfflineAvailable: false,
        isDownloadRestricted: false,
        documentAccessLevel: "1",
        files: [] as any[],
      } as any,
      snakeBarConfig: {
        isOpen: false,
        type: "",
        message: "",
      },
      loading: false,
    });
  };

  const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { snakeBarConfig } = documentConfig;
    snakeBarConfig.isOpen = false;
    snakeBarConfig.message = "";
    snakeBarConfig.type = "success";
    setDocumentConfig({
      ...documentConfig,
      snakeBarConfig,
    });
  };

  const showAlert = (message: string, type: string = "success") => {
    const { snakeBarConfig } = documentConfig;
    snakeBarConfig.isOpen = true;
    snakeBarConfig.message = message;
    snakeBarConfig.type = type;
    setDocumentConfig({
      ...documentConfig,
      snakeBarConfig,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { formData } = documentConfig;

    switch (event.target.name) {
      case "isOfflineAvailable":
      case "isDownloadRestricted":
        formData[event.target.name] = !!event.target.checked;
        break;
      default:
        formData[event.target.name] = event.target.value;
        break;
    }
    setDocumentConfig({ ...documentConfig, formData });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const id = event.target.value as string;
    const { formData } = documentConfig;
    switch (event.target.name) {
      case "category":
        const category = documentConfig.formData.allCategoryList.filter(
          (category: ICategory) => category.id === parseInt(id)
        );
        const subCategoryResponse =
          documentConfig.formData.allCategoryList.filter((cat: any) => {
            return cat.parentCategoryId === category[0]?.id;
          });

        formData.category = category[0];
        formData.subCategoryList = subCategoryResponse;
        formData.subCategory = {};
        break;
      case "subCategory":
        const subCategory = documentConfig.formData.subCategoryList.filter(
          (category: ICategory) => category.id === parseInt(id)
        );
        formData.subCategory = subCategory[0];
        break;

      case "documentAccessLevel":
        formData[event.target.name] = id;
        break;
    }
    setDocumentConfig({ ...documentConfig, formData });
  };

  const getCategoryData = (map: any, tree: any, id: any) => {
    let result = {
      parentCategory: {} as any,
      parentCategoryList: [] as any,
      subCategory: {} as any,
      subCategoryList: [] as any,
    };

    let matchCategory: any = {};
    const traverse = (node: any) => {
      if (!node) return;
      const category = map[node.id];
      if (node.children?.length) {
        if (category.id === id) {
          matchCategory = category;
        }
        if (!category.parentCategoryId) {
          result.parentCategoryList.push(category);
        } else {
          result.subCategoryList.push(category);
        }

        node.children?.forEach(traverse);
      } else {
        if (category.id === id) {
          matchCategory = category;
        }

        if (!category.parentCategoryId) {
          result.parentCategoryList.push(category);
        } else {
          result.subCategoryList.push(category);
        }
      }
    };

    tree.forEach((node: any) => {
      traverse(node);
    });

    if (matchCategory) {
      if (matchCategory.parentCategoryId) {
        result.parentCategory = map[matchCategory.parentCategoryId];
        result.subCategory = map[matchCategory.id];
      } else {
        result.parentCategory = matchCategory;
        result.subCategoryList = [];
      }
    }

    return result;
  };
  const loadDocumentData = (id: number | string | undefined) => {
    if (id) {
      setDocumentConfig({ ...documentConfig, loading: true });
      Promise.all([
        categoryService.get(),
        categoryService.getTree(),
        documentService.getById(id),
      ])
        .then((result) => {
          const [categoryResponse, categoryTree, documentResponse] = result;
          const {
            title,
            isOfflineAvailable,
            documentAccessLevel,
            isDownloadRestricted,
            documentType,
            url,
            categoryId,
          } = documentResponse;

          let categoryMap = GetRowsMapByKey(categoryResponse, "id");
          const {
            parentCategoryList,
            parentCategory,
            subCategoryList,
            subCategory,
          }: any = getCategoryData(categoryMap, categoryTree, categoryId);

          const { formData } = documentConfig;
          formData.categoryList = parentCategoryList;
          formData.category = parentCategory;
          formData.subCategory = subCategory;
          formData.subCategoryList = subCategoryList;
          formData.allCategoryList = categoryResponse;
          formData.type = GetDocumentType(documentType);
          formData.title = title || "";
          formData.url = url || "";
          formData.isOfflineAvailable = isOfflineAvailable;
          formData.isDownloadRestricted = isDownloadRestricted;
          formData.documentAccessLevel = documentAccessLevel;
          setDocumentConfig({ ...documentConfig, formData, loading: false });
        })
        .catch((error) => {
          showAlert(StringUtils.toErrorMessage(error), "error");
          setDocumentConfig({ ...documentConfig, loading: false });
        });
    }
  };

  useEffect(() => {
    loadDocumentData(params.id);
  }, []);

  const getCategoryList = () => {
    return documentConfig.formData.categoryList.map((category: ICategory) => (
      <MenuItem key={category.id} value={category.id}>
        {category.name}
      </MenuItem>
    ));
  };

  const getSubCategoryList = () => {
    return documentConfig.formData.subCategoryList.map(
      (category: ICategory) => (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      )
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event?.target;
    const { formData } = documentConfig;
    if (input.files) {
      for (const file of input.files) {
        formData.files.push(file);
      }
    }
    setDocumentConfig({ ...documentConfig, formData });
  };

  const handleFileDelete = (index: number) => () => {
    const { formData } = documentConfig;

    formData.files = formData.files.filter(
      (_: any, fileIndex: number) => fileIndex !== index
    );

    setDocumentConfig({ ...documentConfig, formData });
  };

  const updateDocument = () => {
    const { formData } = documentConfig;
    const {
      type,
      files,
      category,
      subCategory,
      title,
      url,
      documentAccessLevel,
      isOfflineAvailable,
      isDownloadRestricted,
    } = formData;
    setDocumentConfig({ ...documentConfig, loading: true });
    const request = {
      id: params.id,
      type,
      files,
      categoryId: category?.id,
      subCategoryId: subCategory?.id,
      title,
      url,
      documentAccessLevel,
      isOfflineAvailable,
      isDownloadRestricted,
      isDocumentUpdated: true,
    };

    documentService
      .update(request)
      .then(() => {
        showAlert("Document updated successfully");
        setDocumentConfig({ ...documentConfig, loading: false });
      })
      .catch((error: any) => {
        showAlert(StringUtils.toErrorMessage(error), "error");
        setDocumentConfig({ ...documentConfig, loading: false });
      });
  };

  return (
    <LoadingOverlay
      active={documentConfig.loading}
      spinner
      className="hsc-loader"
    >
      <Box
        component="main"
        sx={{
          paddingLeft: DRAWER_WIDTH,
          background: "#f8f6f6",
          height: "100%",
        }}
      >
        <Box
          sx={{
            padding: "0px 25px 75px 25px",
            textAlign: "left",
            display: "inline-block",
          }}
        >
          <h2>
            <strong>Update File</strong>
          </h2>
        
          <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
            <FormLabel id="document-update-type"></FormLabel>
            <RadioGroup
              row
              aria-labelledby="update-row-radio-buttons-group-label"
              name="type"
              onChange={handleChange}
              value={documentConfig.formData.type}
            >
              <FormControlLabel
                value="1"
                control={
                  <Radio disabled={documentConfig.formData.type !== "1"} />
                }
                label="File"
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio disabled={documentConfig.formData.type !== "2"} />
                }
                label="Link"
              />
            </RadioGroup>
          </FormControl>
          <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
            <FormLabel htmlFor="category-list-select">Category</FormLabel>
            <Select
              id="category-list-select"
              name="category"
              label="Category"
              value={String(documentConfig.formData.category?.id || "")}
              onChange={handleSelectChange}
              fullWidth
              displayEmpty={true}
            >
              <MenuItem value="">Select Category</MenuItem>
              {getCategoryList()}
            </Select>
          </FormControl>
          {documentConfig.formData.subCategoryList.length > 0 && (
            <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
              <FormLabel htmlFor="sub-category-list-select">
                Sub Category
              </FormLabel>
              <Select
                id="sub-category-list-select"
                name="subCategory"
                label="Sub Category"
                value={String(documentConfig.formData.subCategory?.id || "")}
                onChange={handleSelectChange}
                fullWidth
                displayEmpty={true}
              >
                <MenuItem value="">Select Sub Category</MenuItem>
                {getSubCategoryList()}
              </Select>
            </FormControl>
          )}

          <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
            <FormLabel htmlFor="access-level-select">Access Level</FormLabel>
            <Select
              defaultValue="public"
              labelId="demo-select-small"
              name="documentAccessLevel"
              id="access-level-select"
              value={documentConfig.formData.documentAccessLevel}
              label="Access Level"
              onChange={handleSelectChange}
              fullWidth
            >
              <MenuItem value="1">Public</MenuItem>
              <MenuItem value="0">Internal</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
            <TextField
              sx={{ marginBlock: "15px 10px" }}
              name="title"
              required
              id="outlined-link-title"
              label="Title"
              placeholder="Title"
              size="small"
              fullWidth
              value={documentConfig.formData.title}
              onChange={handleChange}
            />
          </FormControl>
          {isFileType({ documentType: documentConfig.formData.type }) && (
            <Box>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginBlock: "5px" }}
                  control={
                    <Checkbox
                      disabled={
                        documentConfig.formData.documentAccessLevel === "0"
                      }
                      name="isOfflineAvailable"
                      checked={documentConfig.formData.isOfflineAvailable}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Available for Offline View"
                />
                <FormControlLabel
                  sx={{ marginBlock: "5px" }}
                  control={
                    <Checkbox
                      name="isDownloadRestricted"
                      disabled={documentConfig.formData.isOfflineAvailable}
                      checked={documentConfig.formData.isDownloadRestricted}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Restrict to Download"
                />
              </FormGroup>
              <Box
                sx={{
                  marginBlock: "0px",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                <Stack direction="row" spacing={0}>
                  <IconButton
                    id="update-files"
                    color="primary"
                    aria-label="update files"
                    component="label"
                    size="small"
                    title=" Upload Files"
                    sx={{ margin: 0, padding: 0, marginBlock: "10px" }}
                  >
                    <input
                      hidden
                      type="file"
                      name="files"
                      onChange={handleFileChange}
                    />
                    Update File &nbsp; &nbsp;{" "}
                    <CloudUploadIcon fontSize="large" />
                  </IconButton>
                </Stack>
              </Box>
              <Paper sx={{ display: "block", background: "transparent" }}>
                {(documentConfig.formData.files || []).map(
                  (data: any, index: number) => (
                    <ListItem key={`file-${index}-${data.lastModified}`}>
                      <Chip
                        label={data.name}
                        onDelete={handleFileDelete(index)}
                      />
                    </ListItem>
                  )
                )}
              </Paper>
            </Box>
          )}

          {isLinkType({ documentType: documentConfig.formData.type }) && (
            <FormControl size="small" fullWidth sx={{ marginBlock: "10px" }}>
              <TextField
                sx={{ marginBlock: "15px 10px" }}
                name="url"
                required
                id="outlined-link-url"
                label="Link"
                placeholder="https://example.com"
                size="small"
                fullWidth
                value={documentConfig.formData.url}
                onChange={handleChange}
              />
            </FormControl>
          )}
          <Box
            sx={{
              marginBlock: "0px",
              padding: "10px 0 0 0",
              margin: "0px",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={5}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={updateDocument}
              >
                Update
              </Button>
              <Button
                onClick={resetConfig}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
        <SnackBar
          id="snackbar-update-document"
          message={documentConfig.snakeBarConfig.message}
          type={documentConfig.snakeBarConfig.type || "success"}
          open={documentConfig.snakeBarConfig.isOpen}
          close={handleSnackBarClose}
        />
      </Box>
    </LoadingOverlay>
  );
};

export default UpdateDocument;
