import { ReactElement, FC, ChangeEvent } from "react";
import { LooseObject } from "../interfaces/LooseObject";
import {
    Box,
    Button,
    Container,
    FormControl,
    TextField,
} from "@mui/material";
import React from "react";
import { Logo } from "./Logo";
import SnackBar from "./SnackBar";
import LoadingOverlay from "react-loading-overlay-ts";
import { SignUpService } from "../services/signup.service";
import { StringUtils } from "../utils/string.utils";

const Signup: FC<LooseObject> = (props: any): ReactElement => {
    const [loading, setLoading] = React.useState(false);

    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarType, setSnackBarType] = React.useState("success");
    const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSnackBarOpen(false);
    };

    const [isSent, setisSent] = React.useState(false);

    const [userData, setUserData] = React.useState({ name: '', otp: '' });

    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        otp: '',
    });

    // State to manage validation errors
    const [errors, setErrors] = React.useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        otp: '',
    });


    // Function to handle form input changes
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // Update form data
        setFormData({
            ...formData,
            [name]: value,
        });

        // Validate the field and update errors
        validateField(name, value);
    };

    // Function to validate a specific field
    const validateField = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'name':
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: !value ? 'Name is required.' : '',
                }));
                break;
            case 'email':
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: !value ? 'Email is required.' : '',
                }));
                break;
            case 'password':
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: validatePassword(value),
                }));
                break;
            case 'confirmPassword':
                const error = !value ? 'Confirm Password is required.' : value == formData.password ? '' : 'Confirm password do not match.'
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: error,
                }));
                break;
            case 'otp':
                if (isSent == true) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [fieldName]: !value ? 'OTP is required.' : '',
                    }));
                }
                break;
            default:
                break;
        }
    };


    const validatePassword = (password: string) => {
        if (typeof password !== 'string') {
            return 'Invalid password format';
        }
        if (password.length < 8) {
            return 'Password must be at least 8 characters long.';
        }
        let conditionCount = 0;
        if (/[A-Z]/.test(password)) conditionCount++; // Uppercase
        if (/[a-z]/.test(password)) conditionCount++; // Lowercase
        if (/\d/.test(password)) conditionCount++; // Numeric
        if (/[^\w\d\s]/.test(password)) conditionCount++; // Special characters

        if (conditionCount < 3) {
            return 'Password must meet three of the four conditions: Uppercase, Lowercase, Numeric, Special characters.';
        }
        // const username = formData.name;   
        // if (username && password.toLowerCase().includes(username.toLowerCase())) {
        //   return "Password cannot contain all or part of the user's account name.";
        // } 
        const email = formData.email;
        if (email && password.toLowerCase().includes(email.split('@')[0].toLowerCase())) {
            return "Password cannot contain all or part of the user's account name.";
        }
        return '';
    };


    const SignupService = new SignUpService();

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            handleSignUp();
        }
    };
    const resetUserState = () => {
        formData.name = '';
        formData.email = '';
        formData.password = '';
        formData.confirmPassword = '';
        formData.otp = '';
    };
    const handleSignUp =  () => {
        validateField("name", formData.name);
        validateField("email", formData.email);
        validateField("password", formData.password);
        validateField("confirmPassword", formData.confirmPassword);
        validateField("otp", formData.otp);

        // Check if there are any validation errors 
        if (Object.values(errors).every((error) => error === '')) {
           // setLoading(true);

            // Check if an OTP has been sent
            if (!isSent) {
                SignupService
                    .sendEmailOtp({ email: formData.email, password: formData.password, isRegister:true })
                    .then((result: any) => {
                        if (result) {
                            setUserData({ name: result.name, otp: result.otp })
                            setisSent(true);
                            setSnackBarMessage("Please enter the OTP sent to your email.");
                            setSnackBarType("success");
                            setSnackBarOpen(true);
                        } else {
                            setSnackBarMessage("Failed to send OTP. Please try again.");
                            setSnackBarType("error");
                            setSnackBarOpen(true);
                        }
                        setLoading(false);
                    })
                    .catch((err: any) => {
                        setLoading(false);
                        setSnackBarMessage(err.message);
                        setSnackBarType("error");
                        setSnackBarOpen(true);
                    });
            } else {
                // Make the registration API call
                SignupService
                    .register({ name: formData.name, email: formData.email, password: formData.password, otp: formData.otp })
                    .then((result: any) => {
                        if (result) {
                            resetUserState();
                            setisSent(false)
                            setSnackBarMessage("Registered Successfully");
                            setSnackBarType("success");
                            setSnackBarOpen(true);
                        } else {
                            setSnackBarMessage("Failed to register. Please try again.");
                            setSnackBarType("error");
                            setSnackBarOpen(true);
                        }
                        setLoading(false);
                    })
                    .catch((err: any) => {
                        setLoading(false);
                        setSnackBarMessage(StringUtils.toErrorMessage(err));
                        setSnackBarType("error");
                        setSnackBarOpen(true);
                    });
            }
        } else {
            //// If there are validation errors, show a message to the user
            //setSnackBarMessage("Please fill in all required fields correctly.");
            //setSnackBarType("error");
            //setSnackBarOpen(true);
        }
    };

    const LogoProps: any = {
        height: "auto",
        width: "100%",
    };

    return (
        <React.Fragment>
            <Container
                sx={{
                    maxWidth: "100%",
                    width: "100%",
                    padding: "15px",
                    textAlign: "center",
                }}
            >
                <LoadingOverlay active={loading} spinner className="hsc-loader">
                    <Box
                        sx={{
                            padding: "25px 10%",
                            textAlign: "center",
                            background: "#f8f6f6", width: "auto", // Changed width to auto for responsiveness
                            maxWidth: "500px", // Added max-width for responsiveness
                            marginLeft: "auto", // Centering the box horizontally
                            marginRight: "auto", // Centering the box horizontally

                        }}
                        onKeyPress={handleKeyPress}
                    >
                        <SnackBar
                            id="snackbar-signup"
                            message={snackBarMessage}
                            type={snackBarType}
                            open={snackBarOpen}
                            close={handleSnackBarClose}
                        />
                        <div style={{ width: "100%" }}>
                            <Logo {...LogoProps} style={{ maxWidth: "400px" }} />
                        </div>
                        <FormControl size="small" fullWidth sx={{ marginBlock: "5px" }}>
                            <TextField
                                sx={{ marginBlock: "10px" }}
                                required
                                id="outlined-name"
                                label="Name"
                                placeholder="Name"
                                size="small"
                                name="name"
                                disabled={isSent}
                                value={formData.name}
                                onChange={handleInputChange}
                                error={errors.name != ""}
                                helperText={errors.name}
                            />
                        </FormControl>
                        <FormControl size="small" fullWidth sx={{ marginBlock: "5px" }}>
                            <TextField
                                sx={{ marginBlock: "10px" }}
                                required
                                id="outlined-email"
                                label="Email"
                                placeholder="Email"
                                size="small"
                                name="email"
                                disabled={isSent}
                                value={formData.email}
                                onChange={handleInputChange}
                                error={errors.email != ""}
                                helperText={errors.email}
                            />
                        </FormControl>
                        <FormControl size="small" fullWidth sx={{ marginBlock: "5px" }}>
                            <TextField
                                sx={{ marginBlock: "10px" }}
                                required
                                id="outlined-password"
                                label="Password"
                                placeholder="Password"
                                size="small"
                                type="password"
                                name="password"
                                disabled={isSent}
                                value={formData.password}
                                onChange={handleInputChange}
                                error={errors.password != ""}
                                helperText={errors.password}
                            />
                        </FormControl>
                        <FormControl size="small" fullWidth sx={{ marginBlock: "5px" }}>
                            <TextField
                                sx={{ marginBlock: "10px" }}
                                required
                                id="outlined-confirm-password"
                                label="Confirm Password"
                                placeholder="Confirm Password"
                                size="small"
                                type="password"
                                name="confirmPassword"
                                disabled={isSent}
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                error={errors.confirmPassword != ""}
                                helperText={errors.confirmPassword}
                            />
                        </FormControl>
                        <FormControl size="small" fullWidth sx={{ marginBlock: "5px", display: isSent ? '' : 'none' }}>
                            <TextField
                                sx={{ marginBlock: "10px" }}
                                required
                                id="outlined-otp"
                                label="OTP"
                                placeholder="OTP"
                                size="small"
                                name="otp"
                                disabled={!isSent}
                                className={props.shouldHide ? 'hidden' : undefined}
                                value={formData.otp}
                                onChange={handleInputChange}
                                error={errors.otp != ""}
                                helperText={errors.otp}
                            />
                        </FormControl>
                        <FormControl
                            size="small"
                            sx={{ marginBlock: "15px", padding: "25px" }}
                        >
                            <Button
                                id="login-submit"
                                variant="contained"
                                onClick={handleSignUp}
                                size="large"
                                color="primary"
                                disableRipple={false}
                                sx={{ padding: "5px 18px", bgcolor: "#c72825" }}
                            >
                                Register
                            </Button>
                        </FormControl>
                    </Box>
                </LoadingOverlay>
            </Container>
        </React.Fragment>
    );
};

export default Signup;
