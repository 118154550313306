import { ApiService } from "./api.service";

export interface IRole {
  id: number | string;
  name: string;
}

export const RoleColumnSetting: any = {
  id: { label: "Id", visibility: false, order: 0 },
  name: { label: "Name", visibility: true, order: 1 },
  created: { label: "Created Date", visibility: true, order: 2 },
  updated: { label: "Updated Date", visibility: true, order: 3 },
  isActive: { label: "Is Active", visibility: false, order: 4 },
  isDeleted: { label: "Is Deleted", visibility: false, order: 5 },
  status: { label: "Status", visibility: true, order: 6 },
  domainEvents: { label: "Domain Events", visibility: false, order: 7 },
};

export class RoleService {
  get() {
    return ApiService.get("Role");
  }

  getById(id: string | number) {
    return ApiService.get(`Role/${id}`);
  }

  add(request: any) {
    return ApiService.post("Role", JSON.stringify(request));
  }

  update(request: any) {
    return ApiService.put("Role", JSON.stringify(request));
  }

  delete(id: string | number) {
    return ApiService.delete(`Role/${id}`);
  }
}
