import { ApiService } from "./api.service";

const USER_ROLE = {
    SUPER_USER: "SuperUser",
    POWER_USER: "PowerUser",
};
export class AuthService {
    isAuthenticated() {
        return !!sessionStorage.getItem("token");
    }

    isSuperUser() {
        return sessionStorage.getItem("role") === USER_ROLE.SUPER_USER;
    }

    getUser() {
        return {
            userName: sessionStorage.getItem("name"),
            userRole: sessionStorage.getItem("role"),
            userId: sessionStorage.getItem("id"),
        };
    }

    async login({ email, password,otp }: any) {
        return new Promise((resolve, reject) => {
            const request = {
                username: email,
                password,
                otp
            };
            ApiService.post("Account/authenticate", JSON.stringify(request))
                .then((result) => {
                    if (result && result.token) {
                        if (result.role == "Contractor") {
                            throw new Error("unauthorized user");
                        }
                        else {
                            sessionStorage.setItem("name", result.name);
                            sessionStorage.setItem("role", result.role);
                            sessionStorage.setItem("id", result.id);
                            sessionStorage.setItem("token", result.token);
                            resolve(result);
                        }
                    } else {
                        reject(result);
                    }
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    logout() {
        return new Promise((resolve) => {
            // fake promise need to be hit the api to delete the user token from backend
            sessionStorage.removeItem("token");
            resolve({ status: 200 });
        });
    }

    async forgotPassword({ email }: any) {
        return new Promise((resolve, reject) => {
            const request = {
                email
            };
            ApiService.post("Account/forgot-password-otp", JSON.stringify(request))
                .then((result) => {
                    if (result && result.otp) {
                        sessionStorage.setItem("otp", result.otp);
                        resolve(result);
                    } else {
                        reject(result);
                    }
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }


    async changePassword({name, email,password, otp }: any) {
        return new Promise((resolve, reject) => {
            const request = {
                name,
                email,
                password,
                otp
            };
            ApiService.post("Account/change-password", JSON.stringify(request))
                .then((result) => {
                    if (result && result.otp) {
                        sessionStorage.setItem("otp", result.otp);
                        resolve(result);
                    } else {
                        reject(result);
                    }
                })
                .catch((err: any) => {
                    reject(err);
                });

        });
    }
}
