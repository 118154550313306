
import Dashboard from "./pages/dashboard/Dashboard";
import Notification from "./pages/notification/Notification";
import Document from "./pages/document/Document";
import UploadDocument from "./pages/upload-document/UploadDocument";
import UpdateDocument from "./pages/update-document/UpdateDocument";
import Category from "./pages/category/Category";

import { FC } from "react";
import User from "./pages/manage-user/User";
import JobSafetyAnalysisWorksheet from "./pages/forms/JobSafetyAnalysisWorksheet";


interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: "dashboard",
    title: "Dashboard",
    path: "/",
    enabled: true,
    component: Dashboard,
  },
  {
    key: "document",
    title: "Document",
    path: "/document",
    enabled: true,
    component: Document,
  },
  {
    key: "upload-document",
    title: "Upload Files",
    path: "/upload-document",
    enabled: true,
    component: UploadDocument,
  },
  {
    key: "update-document",
    title: "Update Files",
    path: "/update-document/:id",
    enabled: true,
    component: UpdateDocument,
  },
  {
    key: "notification",
    title: "Notification",
    path: "/notification",
    enabled: true,
    component: Notification,
  },
  {
    key: "category",
    title: "Category",
    path: "/category",
    enabled: true,
    component: Category,
  },

  {
    key: "manage-user",
    title: "Manage User",
    path: "/manage-user",
    enabled: true,
    component: User,
  },
  {
    key: "job-safety",
    title: "Job Safety",
    path: "/job-safety",
    enabled: true,
    component: JobSafetyAnalysisWorksheet,
  },
];
