/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, FC, useEffect, ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import {
  IUser,
  UserColumnSetting,
  UserService,
} from "../../services/user.service";

import {
  IRole,
  RoleService,
} from "../../services/role.service";

import { LooseObject } from "../../interfaces/LooseObject";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ActiveIcon from "@mui/icons-material/Check";
import DeActiveIcon from "@mui/icons-material/NotInterested";
import { Box, Button, SelectChangeEvent } from "@mui/material";
import { DRAWER_WIDTH } from "../../constants/components";
import SnackBar from "../../components/SnackBar";
import {
  GetColumnVisibilityModel,
  GetSortedColumnByOrder,
  GetRowsMapByKey,
} from "../../utils/grid.utils";
import Dialog from "../../components/Dialog";
import UserDialog from "./UserDialog";
import { useConfirm } from "material-ui-confirm";
import LoadingOverlay from "react-loading-overlay-ts";
import { StringUtils } from "../../utils/string.utils";
import { AuthService } from "../../services/auth.service";
import TabPane, { TabPaneProps } from "../../components/TabPane";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: "#333",
    backgroundColor: "#efeeee",
  },
}));

enum USER_ACTION {
  ADD,
  DELETE,
  EDIT,
} 

const userService = new UserService();
const roleService = new RoleService();
const authService = new AuthService();

const User: FC<LooseObject> = (): ReactElement => {
  const IS_SUPER_USER = authService.isSuperUser();
  const confirm = useConfirm();
  const [loading, setLoading] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarType, setSnackBarType] = React.useState("success");
  const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSnackBarOpen(false);
  };

  const [userAction, setUserAction] = React.useState(USER_ACTION.ADD);
  
  const [showUserDialog, setShowUserDialog] = React.useState(false);

  
  const [userList, setUserList] = React.useState([] as IUser[]);
  const [user, setUser] = React.useState({} as IUser);

  const [roleList, setRoleList] = React.useState([] as IRole[]);
  const [role, setRole] = React.useState({} as IRole);

  const [editUserId, setEditUserId] = React.useState(-1);

  const [email, setEmail] = React.useState("");
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value as string);
  };

  const [password, setPassword] = React.useState("");
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value as string);
  };

  const [name, setName] = React.useState("");
  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    const id = parseInt(event.target.value as string);
    const roleId = roleList.filter((roleId: IRole) => roleId.id === id);
    setRole(roleId[0]);
  };

  const resetUserState = () => {
    setEmail("");
    setPassword("");
    setRole({} as IRole);
    setName("");
  };

  const handleAddUser = () => {
    setUserAction(USER_ACTION.ADD);
    resetUserState();
    setShowUserDialog(true);
  };

  const addUserProps = {
    handleRoleChange,
    handleEmailChange,
    handleUserNameChange,
    handlePasswordChange,
    roleList,
    email,
    name,
    role,
    password,
  };

  const addUser = () => {
    setLoading(true);
    userService
      .add({ name, email, role: role.id, password })
      .then((result: any) => {
        setShowUserDialog(false);
        loadData();
        resetUserState();
        setSnackBarType("success");
        setSnackBarMessage("User added Successfully");
        setSnackBarOpen(true);
      })
      .catch((error) => {
        setShowUserDialog(false);
        setSnackBarMessage(StringUtils.toErrorMessage(error));
        setSnackBarType("error");
        setSnackBarOpen(true);
        resetUserState();
        setLoading(false);
      });
  };

  const editUser = () => {
    const id = editUserId;

    setLoading(true);
    userService
      .update({ id, name, email, role: role.id, password })
      .then((result: any) => {
        setShowUserDialog(false);
        resetUserState();
        setSnackBarMessage("User updated Successfully");
        setSnackBarType("success");
        setSnackBarOpen(true);
        loadData();
      })
      .catch((error) => {
        setShowUserDialog(false);
        setSnackBarMessage(StringUtils.toErrorMessage(error));
        setSnackBarType("error");
        setSnackBarOpen(true);
        resetUserState();
        setLoading(false);
      });
  };

  const userDialogProps = {
    id: "add-user-dialog",
    title: userAction === USER_ACTION.ADD ? "Add User" : "Update User",
    closeButtonText: "Cancel",
    contentText: "",
    visibleActionButton: true,
    submitButtonText: userAction === USER_ACTION.ADD ? "Add" : "Update",
    customComponent: <UserDialog {...addUserProps} />,
    closeAction: () => {
      setShowUserDialog(false);
    },
    submitAction: () => {
      switch (userAction) {
        case USER_ACTION.ADD:
          addUser();
          break;
        case USER_ACTION.EDIT:
          editUser();
          break;
      }
    },
  };

  const [userConfig, setUserConfig] = React.useState({ 
    grid: {
      userRows: [] as IUser[],
      contractorRows: [] as IUser[],
      pendingContractorRows: [] as IUser[],
      columns: [] as any[],
    } as any, 
  }); 

  const deleteUser = React.useCallback(
    (row: any) => () => {
      confirm({ description: "Do you want to delete the user?" })
        .then(() => {
          setLoading(true);
          userService
            .delete(row.id)
            .then(() => {
              setSnackBarMessage("User deleted Successfully");
              setSnackBarType("success");
              setSnackBarOpen(true);
              loadData();
            })
            .catch((error) => {
              setSnackBarMessage(StringUtils.toErrorMessage(error));
              setSnackBarType("error");
              setSnackBarOpen(true);
              setLoading(false);
            });
        })
        .catch(() => {});
    },
    []
  );

  const activeDeactiveUser = React.useCallback(
    (row: any) => () => {
      confirm({ description:row.isActive? "Do you want to deactive the user?" : "Do you want to active the user?"})
        .then(() => {
          setLoading(true);
          userService
            .updateStatus(row.id,!row.isActive)
            .then(() => {
              setSnackBarMessage(row.isActive?"User deactive Successfully":"User active Successfully");
              setSnackBarType("success");
              setSnackBarOpen(true); 
                 loadData(); 
            })
            .catch((error) => {
              setSnackBarMessage(StringUtils.toErrorMessage(error));
              setSnackBarType("error");
              setSnackBarOpen(true);
              setLoading(false);
            });
        })
        .catch(() => {});
    },
    []
  );

  const openEditUserDialog = React.useCallback(
    (userState: any) => () => {
      if (userState.id) {
        Promise.all([
          userService.getById(userState.id),
          roleService.getById(userState.roleId),
        ])
          .then(([user, role]) => {
            setUserAction(USER_ACTION.EDIT);
            setEditUserId(userState.id);
            setEmail(userState.email);
            setName(userState.name);
            setRole(role);
            setUser(user);
            setShowUserDialog(true);
          })
          .catch(() => {});
      } else {
        setUserAction(USER_ACTION.EDIT);
        setEditUserId(userState.id);
        setEmail(userState.email);
        setName(userState.name);
        setUser(userState);
        setShowUserDialog(true);
      }
    },
    []
  );

  const loadData = () => { 
    setLoading(true);
    Promise.all([userService.get(), roleService.get()])
      .then(([users, roles]) => {
        setUserList(users);
        setRoleList(roles);

        const rolesMap = GetRowsMapByKey(roles, "id");
        setRole(roles[0]);

        const docsRows = users.map((row: any, index: number) => {
          return {
            id: row.id,
            ...row,
            roleName: rolesMap[row.roleId].name,
          };
        });

        const docsColumns: any[] = GetSortedColumnByOrder(
          UserColumnSetting
        ).map((key) => {
          const column: any = {
            field: key,
            headerName: UserColumnSetting[key]?.label,
            minWidth: 20,
            flex: 1,
            renderCell: (params: LooseObject) => (
              <span title={params.row[key]} className="table-cell-trucate">
                {params.row[key]}
              </span>
            ),
          };
          return column;
        });

        docsColumns.push({
          field: "actions",
          type: "actions",
          headerName: "Actions",
          minWidth: 60,
          width: 80,
          maxWidth: 100,
          flex: 1,
          getActions: (params: any) => [
            <GridActionsCellItem
              icon={<NoteAltIcon />}
              label="Edit User"
              title="Edit User"
              disabled={!IS_SUPER_USER}
              onClick={openEditUserDialog(params.row)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete User"
              title="Delete User"
              disabled={!IS_SUPER_USER}
              onClick={deleteUser(params.row)}
            />,
            <GridActionsCellItem
                      icon={params.row.isActive?<ActiveIcon />:<DeActiveIcon />}
                      label={params.row.isActive?"User Active":"User active"}
                      title={params.row.isActive?"User deactive":"User deactive"} 
                      disabled={!IS_SUPER_USER}
                      onClick={activeDeactiveUser(params.row)}
                    />,
          ],
        });
        const contractors = docsRows.filter(
          (row: { roleName: string; }) => row.roleName === "Contractor"
        );
        const contractorsRows = contractors.filter(
          (row: { isActive: boolean; }) => row.isActive === true
        );
        const pendingContractorsRows = contractors.filter(
          (row: { isActive: boolean; }) => row.isActive === false
        ); 
        const { grid } = userConfig; 
        grid.columns = docsColumns;
        grid.userRows = docsRows;
        grid.contractorRows = contractorsRows;  
        grid.pendingContractorRows = pendingContractorsRows; 

        setUserConfig({ ...userConfig, grid });
         
        setLoading(false);
      })
      .catch((error) => {
        setSnackBarMessage(StringUtils.toErrorMessage(error));
        setSnackBarType("error");
        setSnackBarOpen(true);
        setLoading(false);
      });
  }; 

  useEffect(() => {
    loadData(); 
    return () => {};
  }, []);

  const tabsProps: TabPaneProps = {
    defaultValue: "users",
    tabs: [
      {
        label: "Users",
        value: "users",
        content: (
          <StripedDataGrid
          key="users-grid"
          initialState={{
            columns: {
              columnVisibilityModel:
                GetColumnVisibilityModel(UserColumnSetting),
            },
          }}
          sx={{ height: "100%", minHeight: "420px", color: "#333" }}
          headerHeight={40}
          rowHeight={35}
          disableSelectionOnClick
          rows={userConfig.grid.userRows}
          columns={userConfig.grid.columns}
          hideFooter={true}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
        />
        ),
      },
      {
        label: "Contractors",
        value: "contractors",
        content: (
          <StripedDataGrid
            key="contractors-grid"
            initialState={{
              columns: {
                columnVisibilityModel:
                  GetColumnVisibilityModel(UserColumnSetting),
              },
            }}
            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
            headerHeight={40}
            rowHeight={35}
            disableSelectionOnClick
            rows={userConfig.grid.contractorRows}
            columns={userConfig.grid.columns}
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        ),
      },
      {
        label: "Pending Approval",
        value: "pendingContractors",
        content: (
          <StripedDataGrid
            key="pending-contractors-grid"
            initialState={{
              columns: {
                columnVisibilityModel:
                  GetColumnVisibilityModel(UserColumnSetting),
              },
            }}
            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
            headerHeight={40}
            rowHeight={35}
            disableSelectionOnClick
            rows={userConfig.grid.pendingContractorRows}
            columns={userConfig.grid.columns}
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        ),
      },
    ],
  }; 

  return (
    <LoadingOverlay active={loading} spinner className="hsc-loader">
      <Box
        component="main"
        sx={{
          paddingLeft: DRAWER_WIDTH,
          background: "#f8f6f6",
          height: "100%",
          paddingBottom: "120px",        
        }}
      >
        <Box 
          sx={{
            padding: "25px",
            width: "100%",
            background: "#f8f6f6",
            height: "100%", 
          }}
        >
          <Box sx={{ textAlign: "right", marginBlock: "10px" }}>
           <Button
              variant="contained"
              onClick={handleAddUser}
              color="primary"
              disabled={!IS_SUPER_USER}
            >
              Add User
            </Button>
            <TabPane {...tabsProps}></TabPane>
          </Box>            
          {showUserDialog && <Dialog {...userDialogProps} />} 
          <SnackBar
            id="snackbar-roleId"
            message={snackBarMessage}
            type={snackBarType || "success"}
            open={snackBarOpen}
            close={handleSnackBarClose}
          />
        </Box>
      </Box>
    </LoadingOverlay>
  );
}; 

export default User;
