import React, { FC, ReactElement } from "react";
import { Box, Divider, Paper } from "@mui/material";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { DRAWER_WIDTH } from "../constants/components";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0.3),
  textAlign: "center",
  color: theme.palette.primary.light,
  boxShadow: "none",
  cursor: "pointer",
  background: "black"
}));

type ItemType = {
  text: string;
  link: string;
  icon?: any;
};

const socialButtons: Array<ItemType> = [
  {
    text: "facebook",
    link: "https://www.facebook.com/conocophillips",
    icon: <FacebookSharpIcon fontSize="small" />,
  },
  {
    text: "twitter",
    link: "https://twitter.com/conocophillips",
    icon: <TwitterIcon fontSize="small" />,
  },
  {
    text: "linkedIn",
    link: "https://www.linkedin.com/company/conocophillips/",
    icon: <LinkedInIcon fontSize="small" />,
  },
  {
    text: "youtube",
    link: "https://www.youtube.com/ConocoPhillips",
    icon: <YouTubeIcon fontSize="small" />,
  },
];

const documents: Array<ItemType> = [
  {
    text: "Terms of use",
    link: "https://www.conocophillips.com/terms-and-conditions/",
  },
  {
    text: "Privacy Statement",
    link: "https://www.conocophillips.com/privacy-policy/",
  },
];

export const Footer: FC = (): ReactElement => {
  const handleItemClick = (
    $event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: ItemType
  ) => {
    window.open(item.link, "_blank");
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "black",
        paddingLeft: DRAWER_WIDTH,
        paddingTop: "1rem",
        paddingBottom: "1rem",
        color: "#fff",
        position: "fixed",
        bottom: 0
      }}
    >
      <Box sx={{ float: "left", paddingLeft: "5px" }}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem sx={{borderColor: "#fff"}} />}
          spacing={1}
        >
          <Item sx={{ cursor: "default" }}>
            &copy;2022 ConocoPhillips Company. All Rights Reserved.
          </Item>
          {documents.map((item, index) => (
            <Item
              key={item.text}
              onClick={(event) => item.link && handleItemClick(event, item)}
            >
              {item.text}
            </Item>
          ))}
        </Stack>
      </Box>
      <Box sx={{ float: "right", paddingRight: "5px" }}>
        <Stack direction="row" spacing={0.4}>
          {socialButtons.map((item, index) => (
            <Item
              key={item.text}
              onClick={(event) => item.link && handleItemClick(event, item)}
            >
              {item.icon}
            </Item>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
