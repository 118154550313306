import { ApiService } from "./api.service";

export interface IUser {
  id: number | string;
  name: string;
}

export const UserColumnSetting: any = {
  name: { label: "Name", visibility: true, order: 0 },
  email: { label: "Email", visibility:true, order:1},
  roleId: { label: "Role Id", visibility: false, order: 2 },
  roleName: { label: "Role Name", visibility: true, order: 3 },
};

export class UserService {
  get() {
    return ApiService.get("User");
  }

  getById(id: string | number) {
    return ApiService.get(`User/${id}`);
  }

  add(request: any) {
    const formData = new FormData();
    formData.append("name", request.name);
    formData.append("email", request.email);
    formData.append("roleId", request.role);
    formData.append("password", request.password);
    return ApiService.postDocument("User/CreateUser", formData);
  }

  update(request: any) {
    const formData = new FormData();
    formData.append("name", request.name);
    formData.append("email", request.email);
    formData.append("roleId", request.role);
    formData.append("password", request.password);
    return ApiService.putDocument(`User/UpdateUser/${request.id}`, formData);
  }

  delete(id: string | number) {
    return ApiService.delete(`User/${id}`);
  }
  updateStatus(id: string | number,isActive:string|boolean) {
    return ApiService.get(`User/UpdateStatus/${id}?IsActive=${isActive}`);
  } 
}
