import React, { ReactElement, FC, useEffect, useCallback } from "react";
import ReactPlayer from "react-player/lazy";

import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  GridRowId,
} from "@mui/x-data-grid";
import {
  DocumentService,
  IDocument,
  ViewType,
} from "../../services/document.service";

import { DashboardColumnSetting } from "../../models/DashboardColumnSetting";
import { LooseObject } from "../../interfaces/LooseObject";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Link, Switch } from "@mui/material";
import { DRAWER_WIDTH } from "../../constants/components";
import TabPane, { TabPaneProps } from "../../components/TabPane";
import SnackBar from "../../components/SnackBar";
import Dialog from "../../components/Dialog";
import {
  GetColumnVisibilityModel,
  GetSortedColumnByOrder,
  GetFileUrl,
} from "../../utils/grid.utils";
import LoadingOverlay from "react-loading-overlay-ts";
import { useConfirm } from "material-ui-confirm";
import { StringUtils } from "../../utils/string.utils";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { AuthService } from "../../services/auth.service";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: "#333",
    backgroundColor: "#efeeee",
  },
}));

const documentService = new DocumentService();
const authService = new AuthService();

const DocViewerComponent: FC<LooseObject> = (props: any): ReactElement => {
  const docs = props.selectedDocuments.map((file: Blob) => {
    return {
      uri: file,
      fileName: "dummyName",
    };
  });

  return (
    <DocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      prefetchMethod="GET"
      config={{
        header: {
          disableHeader: false,
          disableFileName: false,
          retainURLParams: false,
        },
      }}
    />
  );
};

const Dashboard: FC<LooseObject> = (): ReactElement => {
  const IS_SUPER_USER = authService.isSuperUser();
  const { userId } = authService.getUser();

  const navigate = useNavigate();
  const confirm = useConfirm();

  const [dashboardConfig, setDashboardConfig] = React.useState({
    loading: false,
    showDocumentViewer: false,
    showVideoDocumentViewer: false,
    isLinkCopied: false,
    grid: {
      internalRows: [] as any[],
      publicRows: [] as any[],
      columns: [] as any[],
    } as any,
    selectedDocuments: [],
    snakeBarConfig: {
      isOpen: false,
      type: "",
      message: "",
    },
    videoDocumentUrl: "",
  });

  const resetConfig = () => {
    setDashboardConfig({
      ...dashboardConfig,
      loading: false,
    });
  };

  const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { snakeBarConfig } = dashboardConfig;
    snakeBarConfig.isOpen = false;
    snakeBarConfig.message = "";
    snakeBarConfig.type = "";
    setDashboardConfig({
      ...dashboardConfig,
      snakeBarConfig,
    });
  };

  const docViewerDialogProps = {
    id: "doc-viewer-dialog",
    title: "Document Viewer",
    closeButtonText: "Close",
    contentText: "",
    visibleActionButton: true,
    submitButtonText: "Download",
    customComponent: <DocViewerComponent {...dashboardConfig} />,
    closeAction: () => {
      setDashboardConfig({
        ...dashboardConfig,
        showDocumentViewer: false,
      });
    },
    submitAction: () => {},
  };

  const videoDocViewerDialogProps = {
    id: "video-doc-viewer-dialog",
    title: "Video Player",
    contentText: "",
    visibleActionButton: false,
    customComponent: (
      <ReactPlayer
        playing
        controls
        url={dashboardConfig.videoDocumentUrl}
        height="300"
        width="400"
      />
    ),
    closeAction: () => {
      setDashboardConfig({
        ...dashboardConfig,
        showVideoDocumentViewer: false,
      });
    },
  };

  const navigateToEditDocument = useCallback(
    (props: any) => {
      navigate(`/update-document/${props.id}`);
    },
    [navigate]
  );

  const getRowMatchIndex = (data: any, value: any, key: any) => {
    for (let i = 0; i < data.length; i++) {
      if (String(data[i][key]) === String(value[key])) {
        return i;
      }
    }
    return -1;
  };

  const toggleVisibilityChange = (event: any, row: any) => {
    const { grid } = dashboardConfig;
    const type = row.documentAccessLevel === 1 ? "publicRows" : "internalRows";
    const selectedRowIndex = getRowMatchIndex(grid[type], row, "id");

    if (selectedRowIndex !== -1) {
      grid[type][selectedRowIndex].isDocumentVisible =
        !grid[type][selectedRowIndex].isDocumentVisible;
      setDashboardConfig({ ...dashboardConfig, grid, loading: true });
      documentService
        .patch({
          id: row.id,
          title: row.title,
          documentType: row.documentType === "Link" ? "Url" : row.documentType,
          isDocumentVisible: grid[type][selectedRowIndex].isDocumentVisible,
        })
        .then(() => {
          showAlert("Document Updated Successfully!");
          setDashboardConfig({ ...dashboardConfig, loading: false });
        })
        .catch((error: any) => {
          showAlert(StringUtils.toErrorMessage(error), "error");
          setDashboardConfig({ ...dashboardConfig, loading: false });
        });
    }
  };

  const showAlert = (message: string, type: string = "success") => {
    const { snakeBarConfig } = dashboardConfig;

    snakeBarConfig.isOpen = true;
    snakeBarConfig.message = message;
    snakeBarConfig.type = type;
    setDashboardConfig({
      ...dashboardConfig,
      snakeBarConfig,
    });
  };

  const deleteDocument = React.useCallback(
    (row: any) => () => {
      confirm({ description: "Do you want to delete the document?" })
        .then(() => {
          setDashboardConfig({ ...dashboardConfig, loading: true });
          documentService
            .delete(row.id)
            .then(() => {
              showAlert("Document deleted successfully");
              loadDashboardData();
            })
            .catch((error) => {
              showAlert(StringUtils.toErrorMessage(error), "error");
              setDashboardConfig({ ...dashboardConfig, loading: false });
            });
        })
        .catch(() => {});
    },
    []
  );

  const editDocument = React.useCallback(
    (row: GridRowId) => () => {
      navigateToEditDocument(row);
    },
    [navigateToEditDocument]
  );

  const downloadDocument = React.useCallback(
    (row: any) => () => {
      setDashboardConfig({ ...dashboardConfig, loading: true });
      documentService
        .getDocumentById(row.id)
        .then((result) => {
          if (row.isVideo) {
            setDashboardConfig({
              ...dashboardConfig,
              videoDocumentUrl: result.url,
              showVideoDocumentViewer: true,
              loading: false,
            });
            return;
          }

          const newBlob = new Blob([result.blob]);
          const blobUrl = window.URL.createObjectURL(newBlob);
          let alink = document.createElement("a");
          alink.href = blobUrl;
          alink.download = StringUtils.getFileNameFromDisposition(
            result.contentDisposition
          );
          alink.click();
          setDashboardConfig({ ...dashboardConfig, loading: false });
        })
        .catch((error) => {
          showAlert(StringUtils.toErrorMessage(error), "error");
          setDashboardConfig({ ...dashboardConfig, loading: false });
        });
    },
    []
  );

  const hasCurrentUserDocument = (createdById: any) => {
    return createdById.toString() === userId?.toString();
  };

  const getGridActions = (row: any) => {
    const actions = [

      <CopyToClipboard
        text={row.url || GetFileUrl(row.id)}
        onCopy={() => {
          setDashboardConfig({ ...dashboardConfig, isLinkCopied: true });
          showAlert("Link copied");
        }}
      >
        <GridActionsCellItem
          title="Copy Link"
          icon={<ContentCopyIcon />}
          label="Copy Link"
        />
      </CopyToClipboard>,
      <GridActionsCellItem
        title="Download Document"
        icon={<DownloadIcon />}
        label="Download Document"
        disabled={row.documentType !== "File"}
        onClick={downloadDocument(row)}
      />,
      <GridActionsCellItem
        title="Edit Document"
        icon={<NoteAltIcon />}
        label="Edit Document"
        disabled={!IS_SUPER_USER && !hasCurrentUserDocument(row.createdById)}
        onClick={editDocument(row)}
      />,
      <GridActionsCellItem
        title="Delete Document"
        icon={<DeleteIcon />}
        label="Delete Document"
        disabled={!IS_SUPER_USER && !hasCurrentUserDocument(row.createdById)}
        onClick={deleteDocument(row)}
      />,
    ];
    return actions;
  };

  const loadDashboardData = () => {
    setDashboardConfig({ ...dashboardConfig, loading: true });
    const dashboardResponse: Promise<IDocument[]> = documentService.get(
      ViewType.VIEW_FILE
    );
    dashboardResponse
      .then((result: IDocument[]) => {
        const docsRows = result.map((row: any, index: number) => {
          return {
            id: index,
            ...row,
            documentType:
              row.documentType?.toLowerCase() === "url"
                ? "Link"
                : row.documentType,
          };
        });

        const docsColumns: any[] = GetSortedColumnByOrder(
          DashboardColumnSetting
        ).map((key) => {
          const column: any = {
            field: key,
            headerName: DashboardColumnSetting[key]?.label,
            minWidth: DashboardColumnSetting[key]?.minWidth,
            width: DashboardColumnSetting[key]?.width,
            maxWidth: DashboardColumnSetting[key]?.maxWidth,
            flex: 1,
            renderCell: (params: LooseObject) => (
              <span title={params.row[key]} className="table-cell-trucate">
                {params.row[key]}
              </span>
            ),
          };

          switch (column.field) {
            case "isDocumentVisible":
              column.renderCell = (params: LooseObject) => {
                return (
                  <Switch
                    color="success"
                    size="small"
                    checked={params.row.isDocumentVisible}
                    onChange={(event) =>
                      toggleVisibilityChange(event, params.row)
                    }
                    name={column.headerName}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                );
              };
              break;
            case "created":
            case "updated":
              column.renderCell = (params: LooseObject) => {
                return new Date(params.row[key]).toLocaleString();
              };
              break;
            case "title":
              column.renderCell = (params: LooseObject) => {
                if (params.row.documentType === "Link") {
                  return (
                    <Link
                      title={params.row[key]}
                      component="button"
                      onClick={() => {
                        window.open(params.row.url, "_blank");
                      }}
                    >
                      {params.row[key]}
                    </Link>
                  );
                }
                return (
                  <span className="table-cell-trucate" title={params.row[key]}>
                    {params.row[key]}
                  </span>
                );
              };
          }

          return column;
        });

        docsColumns.push({
          field: "actions",
          type: "actions",
          headerName: "Actions",
          minWidth: 180,
          width: 180,
          maxWidth: 180,
          flex: 1,
          getActions: (params: any) => getGridActions(params.row),
        });
        const internalRows = docsRows.filter(
          (row) => row.documentAccessLevel === 0
        );
        const publicRows = docsRows.filter(
          (row) => row.documentAccessLevel === 1
        );
        const { grid } = dashboardConfig;

        grid.columns = docsColumns;
        grid.internalRows = internalRows;
        grid.publicRows = publicRows;

        setDashboardConfig({ ...dashboardConfig, grid });
        resetConfig();
      })
      .catch((error) => {
        showAlert(StringUtils.toErrorMessage(error), "error");
        setDashboardConfig({ ...dashboardConfig, loading: false });
      });
  };

  useEffect(() => {
    loadDashboardData();
    return () => {};
  }, []);

  const tabsProps: TabPaneProps = {
    defaultValue: "public",
    tabs: [
      {
        label: "Public",
        value: "public",
        content: (
          <StripedDataGrid
            key="public-document-grid"
            initialState={{
              columns: {
                columnVisibilityModel: GetColumnVisibilityModel(
                  DashboardColumnSetting
                ),
              },
            }}
            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
            headerHeight={40}
            rowHeight={35}
            disableSelectionOnClick
            rows={dashboardConfig.grid.publicRows}
            columns={dashboardConfig.grid.columns}
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        ),
      },
      {
        label: "Internal",
        value: "internal",
        content: (
          <StripedDataGrid
            key="internal-document-grid"
            initialState={{
              columns: {
                columnVisibilityModel: GetColumnVisibilityModel(
                  DashboardColumnSetting
                ),
              },
            }}
            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
            headerHeight={40}
            rowHeight={35}
            disableSelectionOnClick
            rows={dashboardConfig.grid.internalRows}
            columns={dashboardConfig.grid.columns}
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        ),
      },
    ],
  };

  return (
    <LoadingOverlay
      active={dashboardConfig.loading}
      spinner
      className="hsc-loader"
    >
      <Box
        sx={{
          flexGrow: 1,
          backgroud: "#f8f6f6",
          paddingLeft: DRAWER_WIDTH,
          height: "100%",
        }}
      >
        {dashboardConfig.showDocumentViewer && (
          <Dialog {...docViewerDialogProps} />
        )}

        {dashboardConfig.showVideoDocumentViewer && (
          <Dialog {...videoDocViewerDialogProps} />
        )}
        <TabPane {...tabsProps}></TabPane>
        <SnackBar
          id="snackbar-dashboard"
          message={dashboardConfig.snakeBarConfig.message}
          type={dashboardConfig.snakeBarConfig.type || "success"}
          open={dashboardConfig.snakeBarConfig.isOpen}
          close={handleSnackBarClose}
        />
      </Box>
    </LoadingOverlay>
  );
};

export default Dashboard;
