import React, { ReactElement, FC, useEffect, useCallback } from "react";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  GridRowId,
} from "@mui/x-data-grid";
import {
  DocumentService,
  IDocument,
  ViewType,
} from "../../services/document.service";

import { DocumentColumnSetting } from "../../models/DocumentColumnSetting";
import { LooseObject } from "../../interfaces/LooseObject";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Link, Switch } from "@mui/material";
import { DRAWER_WIDTH } from "../../constants/components";
import TabPane, { TabPaneProps } from "../../components/TabPane";
import SnackBar from "../../components/SnackBar";
import Dialog from "../../components/Dialog";
import {
  GetColumnVisibilityModel,
  GetSortedColumnByOrder,
  GetFileUrl,
} from "../../utils/grid.utils";
import LoadingOverlay from "react-loading-overlay-ts";
import { useConfirm } from "material-ui-confirm";
import { StringUtils } from "../../utils/string.utils";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { CopyToClipboard } from "react-copy-to-clipboard";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: "#333",
    backgroundColor: "#efeeee",
  },
}));

const documentService = new DocumentService();

const DocViewerComponent: FC<LooseObject> = (props: any): ReactElement => {
  const docs = props.selectedDocuments.map((file: Blob) => {
    return {
      uri: file || "https://agisdevblob.blob.core.windows.net/cop-hse/de50fce7-80a8-4f71-ae39-75c001f8df5e",
      fileName: "dummyName",
    };
  });

  return (
    <DocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      prefetchMethod="GET"
      config={{
        header: {
          disableHeader: false,
          disableFileName: false,
          retainURLParams: false,
        },
      }}
    />
  );
};

const Document: FC<LooseObject> = (): ReactElement => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [documentConfig, setDocumentConfig] = React.useState({
    loading: false,
    showDocumentViewer: false,
    showVideoDocumentViewer: false,
    isLinkCopied: false,
    grid: {
      internalRows: [] as any[],
      publicRows: [] as any[],
      columns: [] as any[],
    } as any,
    selectedDocuments: [],
    snakeBarConfig: {
      isOpen: false,
      type: "",
      message: "",
    },
    videoDocumentUrl: "",
  });

  const resetConfig = () => {
    setDocumentConfig({
      ...documentConfig,
      loading: false,
    });
  };

  const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { snakeBarConfig } = documentConfig;
    snakeBarConfig.isOpen = false;
    snakeBarConfig.message = "";
    snakeBarConfig.type = "";
    setDocumentConfig({
      ...documentConfig,
      snakeBarConfig,
    });
  };

  const docViewerDialogProps = {
    id: "doc-viewer-dialog",
    title: "Document Viewer",
    closeButtonText: "Close",
    contentText: "",
    visibleActionButton: true,
    submitButtonText: "Download",
    customComponent: <DocViewerComponent {...documentConfig} />,
    closeAction: () => {
      setDocumentConfig({
        ...documentConfig,
        showDocumentViewer: false,
      });
    },
    submitAction: () => {},
  };

  const videoDocViewerDialogProps = {
    id: "video-doc-viewer-dialog",
    title: "Video Player",
    contentText: "",
    visibleActionButton: false,
    customComponent: (
      <ReactPlayer
        playing
        controls
        url={documentConfig.videoDocumentUrl}
        height="300"
        width="400"
      />
    ),
    closeAction: () => {
      setDocumentConfig({
        ...documentConfig,
        showVideoDocumentViewer: false,
      });
    },
  };

  const navigateToEditDocument = useCallback(
    (props: any) => {
      navigate(`/update-document/${props.id}`);
    },
    [navigate]
  );

  const getRowMatchIndex = (data: any, value: any, key: any) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i][key] === value[key]) {
        return i;
      }
    }
    return -1;
  };

  const toggleVisibilityChange = (event: any, row: any) => {
    const { grid } = documentConfig;
    const type = row.documentAccessLevel === 1 ? "publicRows" : "internalRows";
    const selectedRowIndex = getRowMatchIndex(grid[type], row, "id");

    if (selectedRowIndex !== -1) {
      grid[type][selectedRowIndex].isDocumentVisible =
        !grid[type][selectedRowIndex].isDocumentVisible;
      setDocumentConfig({ ...documentConfig, grid, loading: true });
      documentService
        .patch({
          isDocumentVisible: grid[type][selectedRowIndex].isDocumentVisible,
          title: row.title,
          documentType: row.documentType,
          id: row.id,
        })
        .then(() => {
          loadDocumentData();
        })
        .catch((error) => {
          showAlert(StringUtils.toErrorMessage(error), "error");
        });
    }
  };

  const showAlert = (message: string, type: string = "success") => {
    const { snakeBarConfig } = documentConfig;

    snakeBarConfig.isOpen = true;
    snakeBarConfig.message = message;
    snakeBarConfig.type = type;
    setDocumentConfig({
      ...documentConfig,
      snakeBarConfig,
    });
  };

  const deleteDocument = React.useCallback(
    (row: any) => () => {
      confirm({ description: "Do you want to delete the document?" })
        .then(() => {
          setDocumentConfig({ ...documentConfig, loading: true });
          documentService
            .delete(row.id)
            .then(() => {
              showAlert("Document deleted successfully");
              loadDocumentData();
            })
            .catch((error) => {
              showAlert(StringUtils.toErrorMessage(error), "error");
              setDocumentConfig({ ...documentConfig, loading: false });
            });
        })
        .catch(() => {});
    },
    []
  );

  const editDocument = React.useCallback(
    (row: GridRowId) => () => {
      navigateToEditDocument(row);
    },
    [navigateToEditDocument]
  );

  const downloadDocument = React.useCallback(
    (row: any) => () => {
      setDocumentConfig({ ...documentConfig, loading: true });
      documentService
        .getDocumentById(row.id)
        .then((result) => {
          if (row.isVideo) {
            setDocumentConfig({
              ...documentConfig,
              videoDocumentUrl: result.url,
              showVideoDocumentViewer: true,
              loading: false,
            });
            return;
          }
          const newBlob = new Blob([result.blob]);
          const blobUrl = window.URL.createObjectURL(newBlob);
          let alink = document.createElement("a");
          alink.href = blobUrl;
          alink.download = StringUtils.getFileNameFromDisposition(
            result.contentDisposition
          );
          alink.click();
          setDocumentConfig({ ...documentConfig, loading: false });
        })
        .catch((error) => {
          showAlert(StringUtils.toErrorMessage(error), "error");
          setDocumentConfig({ ...documentConfig, loading: false });
        });
    },
    []
  );

  const getGridActions = (row: any) => {
    const actions = [
      // <GridActionsCellItem
      //   title="View Document"
      //   icon={<VisibilityOffIcon />}
      //   label="View Document"
      //   onClick={viewDocument(row)}
      // />,

      <CopyToClipboard
        text={row.url || GetFileUrl(row.id)}
        onCopy={() => {
          setDocumentConfig({ ...documentConfig, isLinkCopied: true });
          showAlert("Link copied");
        }}
      >
        <GridActionsCellItem
          title="Copy Link"
          icon={<ContentCopyIcon />}
          label="Copy Link"
        />
      </CopyToClipboard>,
      <GridActionsCellItem
        title="Download Document"
        icon={<DownloadIcon />}
        label="Download Document"
        disabled={row.documentType !== "File"}
        onClick={downloadDocument(row)}
      />,
      <GridActionsCellItem
        title="Edit Document"
        icon={<NoteAltIcon />}
        label="Edit Document"
        onClick={editDocument(row)}
      />,
      <GridActionsCellItem
        title="Delete Document"
        icon={<DeleteIcon />}
        label="Delete Document"
        onClick={deleteDocument(row)}
      />,
    ];
    return actions;
  };

  const loadDocumentData = () => {
    setDocumentConfig({ ...documentConfig, loading: true });
    const documentResponse: Promise<IDocument[]> = documentService.get(
      ViewType.MY_UPLOAD
    );
    documentResponse
      .then((result: IDocument[]) => {
        const docsRows = result.map((row: any, index: number) => {
          return {
            id: index,
            ...row,
            documentType:
              row.documentType?.toLowerCase() === "url"
                ? "Link"
                : row.documentType,
          };
        });
        const docsColumns: any[] = GetSortedColumnByOrder(
          DocumentColumnSetting
        ).map((key) => {
          const column: any = {
            field: key,
            headerName: DocumentColumnSetting[key]?.label,
            minWidth: DocumentColumnSetting[key]?.minWidth,
            width: DocumentColumnSetting[key]?.width,
            maxWidth: DocumentColumnSetting[key]?.maxWidth,
            flex: 1,
            renderCell: (params: LooseObject) => (
              <span title={params.row[key]} className="table-cell-trucate">
                {params.row[key]}
              </span>
            ),
          };

          switch (column.field) {
            case "isDocumentVisible":
              column.renderCell = (params: LooseObject) => {
                return (
                  <Switch
                    color="success"
                    size="small"
                    checked={params.row.isDocumentVisible}
                    onChange={(event) =>
                      toggleVisibilityChange(event, params.row)
                    }
                    name={column.headerName}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                );
              };
              break;
            case "created":
            case "updated":
              column.renderCell = (params: LooseObject) => {
                //return dayjs(params.row[key]).format("M/D/YYYY, h:mm A");
                return new Date(params.row[key]).toLocaleString();
              };
              break;
            case "title":
              column.renderCell = (params: LooseObject) => {
                if (params.row.documentType === "Link") {
                  return (
                    <Link
                      title={params.row[key]}
                      component="button"
                      onClick={() => {
                        window.open(params.row.url, "_blank");
                      }}
                    >
                      {params.row[key]}
                    </Link>
                  );
                }
                return (
                  <span className="table-cell-trucate" title={params.row[key]}>
                    {params.row[key]}
                  </span>
                );
              };
          }

          return column;
        });

        docsColumns.push({
          field: "actions",
          type: "actions",
          headerName: "Actions",
          minWidth: 180,
          width: 180,
          maxWidth: 180,
          flex: 1,
          getActions: (params: any) => getGridActions(params.row),
        });
        const internalRows = docsRows.filter(
          (row) => row.documentAccessLevel === 0
        );
        const publicRows = docsRows.filter(
          (row) => row.documentAccessLevel === 1
        );
        const { grid } = documentConfig;

        grid.columns = docsColumns;
        grid.internalRows = internalRows;
        grid.publicRows = publicRows;

        setDocumentConfig({ ...documentConfig, grid });
        resetConfig();
      })
      .catch((error) => {
        showAlert(StringUtils.toErrorMessage(error), "error");
        setDocumentConfig({ ...documentConfig, loading: false });
      });
  };

  useEffect(() => {
    loadDocumentData();
    return () => {};
  }, []);

  const tabsProps: TabPaneProps = {
    defaultValue: "public",
    tabs: [
      {
        label: "Public",
        value: "public",
        content: (
          <StripedDataGrid
            key="public-document-grid"
            initialState={{
              columns: {
                columnVisibilityModel: GetColumnVisibilityModel(
                  DocumentColumnSetting
                ),
              },
            }}
            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
            headerHeight={40}
            rowHeight={35}
            disableSelectionOnClick
            rows={documentConfig.grid.publicRows}
            columns={documentConfig.grid.columns}
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        ),
      },
      {
        label: "Internal",
        value: "internal",
        content: (
          <StripedDataGrid
            key="internal-document-grid"
            initialState={{
              columns: {
                columnVisibilityModel: GetColumnVisibilityModel(
                  DocumentColumnSetting
                ),
              },
            }}
            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
            headerHeight={40}
            rowHeight={35}
            disableSelectionOnClick
            rows={documentConfig.grid.internalRows}
            columns={documentConfig.grid.columns}
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        ),
      },
    ],
  };

  return (
    <LoadingOverlay
      active={documentConfig.loading}
      spinner
      className="hsc-loader"
    >
      <Box
        sx={{
          flexGrow: 1,
          backgroud: "#f8f6f6",
          paddingLeft: DRAWER_WIDTH,
          height: "100%",
        }}
      >
        {documentConfig.showDocumentViewer && (
          <Dialog {...docViewerDialogProps} />
        )}
        {documentConfig.showVideoDocumentViewer && (
          <Dialog {...videoDocViewerDialogProps} />
        )}
        <TabPane {...tabsProps}></TabPane>
        <SnackBar
          id="snackbar-document"
          message={documentConfig.snakeBarConfig.message}
          type={documentConfig.snakeBarConfig.type || "success"}
          open={documentConfig.snakeBarConfig.isOpen}
          close={handleSnackBarClose}
        />
      </Box>
    </LoadingOverlay>
  );
};

export default Document;
