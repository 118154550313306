import React, { ReactElement, FC, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import {
  ICategory,
  CategoryColumnSetting,
  CategoryService,
} from "../../services/category.service";

import { LooseObject } from "../../interfaces/LooseObject";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { Box, Button } from "@mui/material";
import { DRAWER_WIDTH } from "../../constants/components";
import SnackBar from "../../components/SnackBar";
import {
  GetColumnVisibilityModel,
  GetSortedColumnByOrder,
  GetRowsMapByKey,
} from "../../utils/grid.utils";
import Dialog from "../../components/Dialog";
import AddCategoryDialog from "./AddCategoryDialog";
import UpdateCategoryDialog from "./UpdateCategoryDialog";
import { useConfirm } from "material-ui-confirm";
import LoadingOverlay from "react-loading-overlay-ts";
import { StringUtils } from "../../utils/string.utils";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: "#333",
    backgroundColor: "#efeeee",
  },
}));

const categoryService = new CategoryService();

const Category: FC<LooseObject> = (): ReactElement => {
  const confirm = useConfirm();

  const [categoryConfig, setCategoryConfig] = React.useState({
    loading: false,
    grid: {
      rows: [] as any[],
      columns: [] as any[],
    } as any,
    dialogConfig: {
      showAddCategoryDialog: false,
      showUpdateCategoryDialog: false,
      parentCategoryId: "",
      editCategoryId: "",
      categoryList: [] as any[],
      category: {} as any,
      iconImage: "",
      previewIconImage: "",
      url: "",
      name: "",
      isLink: false,
    } as any,
    snakeBarConfig: {
      isOpen: false,
      type: "",
      message: "",
    },
  });

  const resetConfig = () => {
    const { dialogConfig } = categoryConfig;
    dialogConfig.showAddCategoryDialog = false;
    dialogConfig.showUpdateCategoryDialog = false;
    dialogConfig.parentCategoryId = "";
    dialogConfig.editCategoryId = "";
    dialogConfig.category = {};
    dialogConfig.iconImage = "";
    dialogConfig.previewIconImage = "";
    dialogConfig.url = "";
    dialogConfig.name = "";
    dialogConfig.isLink = false;
    setCategoryConfig({
      ...categoryConfig,
      loading: false,
      dialogConfig,
    });
  };

  const handleSnackBarClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { snakeBarConfig } = categoryConfig;
    snakeBarConfig.isOpen = false;
    snakeBarConfig.message = "";
    snakeBarConfig.type = "";
    setCategoryConfig({
      ...categoryConfig,
      snakeBarConfig,
    });
  };

  const showAlert = (message: string, type: string = "success") => {
    const { snakeBarConfig } = categoryConfig;
    snakeBarConfig.isOpen = true;
    snakeBarConfig.message = message;
    snakeBarConfig.type = type;
    setCategoryConfig({
      ...categoryConfig,
      snakeBarConfig,
    });
  };

  const handleAddCategory = () => {
    resetConfig();
    const { dialogConfig } = categoryConfig;
    dialogConfig.showAddCategoryDialog = true;
    setCategoryConfig({ ...categoryConfig, dialogConfig });
  };

  const addCategory = () => {
    setCategoryConfig({ ...categoryConfig, loading: true });
    const { dialogConfig } = categoryConfig;
    const { name, url, iconImage, parentCategoryId, isLink } = dialogConfig;
    categoryService
      .add({ name, url, iconImage, parentCategoryId, isLink })
      .then((result: any) => {
        showAlert("Category added Successfully");
        loadCategoryData();
        resetConfig();
      })
      .catch((error) => {
        showAlert(StringUtils.toErrorMessage(error), "error");
        resetConfig();
      });
  };

  const updateCategory = async () => {
    setCategoryConfig({ ...categoryConfig, loading: true });
    const { dialogConfig } = categoryConfig;
    const id = dialogConfig.editCategoryId;
    const { name, url, iconImage, parentCategoryId, isLink } = dialogConfig;
    categoryService
      .update({ id, name, url, iconImage, parentCategoryId, isLink })
      .then((result: any) => {
        showAlert("Category updated Successfully");
        loadCategoryData();
        resetConfig();
      })
      .catch((error) => {
        showAlert(StringUtils.toErrorMessage(error), "error");
        resetConfig();
      });
  };

  const deleteCategory = React.useCallback(
    (row: any) => () => {
      confirm({ description: "Do you want to delete the category?" })
        .then(() => {
          setCategoryConfig({ ...categoryConfig, loading: true });
          categoryService
            .delete(row.id)
            .then(() => {
              showAlert("Category deleted successfully");
              loadCategoryData();
            })
            .catch((error) => {
              showAlert(StringUtils.toErrorMessage(error), "error");
              setCategoryConfig({ ...categoryConfig, loading: false });
            });
        })
        .catch(() => {});
    },
    []
  );

  const addCategoryDialogProps = {
    id: "add-category-dialog",
    title: "Add Category",
    closeButtonText: "Cancel",
    contentText: "",
    visibleActionButton: true,
    submitButtonText: "Add",
    customComponent: (
      <AddCategoryDialog
        onChange={(dialogState: any) => {
          let { dialogConfig } = categoryConfig;
          dialogConfig = { ...dialogConfig, ...dialogState };
          setCategoryConfig({ ...categoryConfig, dialogConfig });
        }}
        rows={categoryConfig.grid.rows}
      />
    ),
    closeAction: () => {
      const { dialogConfig } = categoryConfig;
      dialogConfig.showAddCategoryDialog = false;
      setCategoryConfig({ ...categoryConfig, dialogConfig });
    },
    submitAction: () => {
      addCategory();
    },
  };

  const updateCategoryDialogProps = {
    id: "update-category-dialog",
    title: "Update Category",
    closeButtonText: "Cancel",
    contentText: "",
    visibleActionButton: true,
    submitButtonText: "Update",
    customComponent: (
      <UpdateCategoryDialog
        onChange={(dialogState: any) => {
          let { dialogConfig } = categoryConfig;
          dialogConfig = { ...dialogConfig, ...dialogState };
          setCategoryConfig({ ...categoryConfig, dialogConfig });
        }}
        rows={categoryConfig.grid.rows}
        state={categoryConfig.dialogConfig}
      />
    ),
    closeAction: () => {
      const { dialogConfig } = categoryConfig;
      dialogConfig.showUpdateCategoryDialog = false;
      setCategoryConfig({ ...categoryConfig, dialogConfig });
    },
    submitAction: () => {
      updateCategory();
    },
  };

  const openEditCategoryDialog = React.useCallback(
    (categoryState: any) => () => {
      const { dialogConfig } = categoryConfig;
      if (categoryState.parentCategoryId) {
        categoryService
          .getById(categoryState.parentCategoryId)
          .then((category) => {
            dialogConfig.editCategoryId = categoryState.id;
            dialogConfig.name = categoryState.name;
            dialogConfig.url = categoryState.url;

            dialogConfig.isLink = categoryState.isLink;
            dialogConfig.iconImage = categoryState.iconImage;
            dialogConfig.previewIconImage = categoryState.previewIconImage;
            dialogConfig.parentCategoryId = categoryState.parentCategoryId;
            dialogConfig.category = category;
            dialogConfig.showUpdateCategoryDialog = true;

            setCategoryConfig({ ...categoryConfig, dialogConfig });
          });
      } else {
        dialogConfig.editCategoryId = categoryState.id;
        dialogConfig.name = categoryState.name;
        dialogConfig.url = categoryState.url;

        dialogConfig.isLink = categoryState.isLink;
        dialogConfig.iconImage = categoryState.iconImage;
        dialogConfig.previewIconImage = categoryState.previewIconImage;
        dialogConfig.parentCategoryId = categoryState.parentCategoryId;
        dialogConfig.showUpdateCategoryDialog = true;
        setCategoryConfig({ ...categoryConfig, dialogConfig });
      }
    },
    []
  );

  const loadCategoryData = () => {
    setCategoryConfig({ ...categoryConfig, loading: true });
    const categoryResponse: Promise<ICategory[]> = categoryService.get();
    categoryResponse
      .then((result: ICategory[]) => {
        const parentCategoryMap: any = GetRowsMapByKey(result, "id");

        const docsRows = result.map((row: any, index: number) => {
          return {
            id: index,
            createdBy: row.createdBy || "Admin",
            ...row,
            parentCategoryName:
              parentCategoryMap[row.parentCategoryId]?.name ||
              row.parentCategoryId,
            previewIconImage: row.iconImage
              ? "data:image/png;base64, " + row.iconImage
              : null,
          };
        });

        const docsColumns: any[] = GetSortedColumnByOrder(
          CategoryColumnSetting
        ).map((key) => {
          const column: any = {
            field: key,
            headerName: CategoryColumnSetting[key]?.label,
            minWidth: CategoryColumnSetting[key]?.minWidth,
            width: CategoryColumnSetting[key]?.width,
            maxWidth: CategoryColumnSetting[key]?.maxWidth,
            flex: 1,
            renderCell: (params: LooseObject) => (
              <span title={params.row[key]} className="table-cell-trucate">
                {params.row[key]}
              </span>
            ),
          };

          switch (column.field) {
            case "iconImage":
              column.renderCell = (params: LooseObject) => {
                if (params.row.previewIconImage) {
                  return (
                    <img
                      src={params.row.previewIconImage}
                      alt={params.row.title}
                      height="30px"
                      width="30px"
                    />
                  );
                }
              };
              break;
          }

          return column;
        });

        docsColumns.push({
          field: "actions",
          type: "actions",
          headerName: "Actions",
          minWidth: 60,
          width: 80,
          maxWidth: 100,
          flex: 1,
          getActions: (params: any) => [
            <GridActionsCellItem
              icon={<NoteAltIcon />}
              title="Edit Category"
              label="Edit Category"
              onClick={openEditCategoryDialog(params.row)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              title="Delete Category"
              label="Delete Category"
              onClick={deleteCategory(params.row)}
            />,
          ],
        });

        const { grid, dialogConfig } = categoryConfig;
        grid.columns = docsColumns;
        grid.rows = docsRows;
        dialogConfig.categoryList = result;
        setCategoryConfig({ ...categoryConfig, grid, dialogConfig });
        resetConfig();
      })
      .catch((error) => {
        showAlert(StringUtils.toErrorMessage(error), "error");
      });
  };

  useEffect(() => {
    loadCategoryData();
    return () => {
      if (categoryConfig.dialogConfig.previewIconImage)
        URL.revokeObjectURL(categoryConfig.dialogConfig.previewIconImage);
    };
  }, []);

  return (
    <LoadingOverlay
      active={categoryConfig.loading}
      spinner
      className="hsc-loader"
    >
      <Box
        component="main"
        sx={{
          paddingLeft: DRAWER_WIDTH,
          background: "#f8f6f6",
          height: "100%",
          paddingBottom: "120px",
        }}
      >
        <Box
          sx={{
            padding: "25px",
            width: "100%",
            background: "#f8f6f6",
            height: "100%",
          }}
        >
          <Box sx={{ textAlign: "right", marginBlock: "10px" }}>
            <Button
              variant="contained"
              onClick={handleAddCategory}
              color="primary"
            >
              Add Category
            </Button>
          </Box>
          {categoryConfig.dialogConfig.showAddCategoryDialog && (
            <Dialog {...addCategoryDialogProps} />
          )}

          {categoryConfig.dialogConfig.showUpdateCategoryDialog && (
            <Dialog {...updateCategoryDialogProps} />
          )}
          <StripedDataGrid
            key="internal-document-grid"
            initialState={{
              columns: {
                columnVisibilityModel: GetColumnVisibilityModel(
                  CategoryColumnSetting
                ),
              },
            }}
            sx={{ height: "100%", minHeight: "420px", color: "#333" }}
            headerHeight={40}
            rowHeight={35}
            disableSelectionOnClick
            rows={categoryConfig.grid.rows}
            columns={categoryConfig.grid.columns}
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
          <SnackBar
            id="snackbar-category"
            message={categoryConfig.snakeBarConfig.message}
            type={categoryConfig.snakeBarConfig.type || "success"}
            open={categoryConfig.snakeBarConfig.isOpen}
            close={handleSnackBarClose}
          />
        </Box>
      </Box>
    </LoadingOverlay>
  );
};

export default Category;
