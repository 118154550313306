import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ResponsiveDialog(props: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      id={props.id}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      open={true}
      onClose={props.closeAction}
      aria-labelledby={props.title}
      sx={{ padding: "10px", overflow: "hidden" }}
    >
      <DialogTitle id={`title-${props.id}`} sx={{ fontWeight: "900" }}>
        {props.title}
        {!props.visibleActionButton && (
          <IconButton
            onClick={props.closeAction}
            style={{ position: "relative", float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minBlockSize: "200px" }}>
        {props.contentText && (
          <DialogContentText>{props.contentText}</DialogContentText>
        )}
        {props.customComponent && props.customComponent}
      </DialogContent>
      {props.visibleActionButton && (
        <DialogActions
          id="app-dialog"
          sx={{ justifyContent: "center", margin: "20px" }}
        >
          <Button
            id="close"
            variant="contained"
            autoFocus
            onClick={props.closeAction}
            size="large"
            color="primary"
          >
            {props.closeButtonText}
          </Button>
          <Button
            id="submit"
            variant="contained"
            onClick={props.submitAction}
            size="large"
            color="success"
            sx={{ padding: "10px" }}
          >
            {props.submitButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
